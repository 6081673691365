import React from 'react';

const thankyou = () => {
  return (
    <>
        <div className='thankyou-page'>
            <div className='thankyou-cover'>
                <h1>Thanks For Completing <br/>The Registration</h1>
                <p>One of our consultants will contact <br/>you within the next 48 hours.</p>
                <a href='/'>Back to Home Page</a>
            </div>
        </div>
    </>
  )
}

export default thankyou