import React from 'react';
import Homepage from '../components/Homepage/Homepage';
// import HomepageAr from '../components/Homepage/HomepageAr';

const Home = () => {
    return (
        <>
             <Homepage />
            {/*<HomepageAr />*/}
        </>
    )
}

export default Home