import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import FooterLogo from '../../assets/footer-images/Footer-logo-ar.svg';
import FBIcon from '../../assets/footer-images/fb-icon.svg';
import InstaIcon from '../../assets/footer-images/Instagram-icon.svg';
import LinkedinIcon from '../../assets/footer-images/Linkedin-icon.svg';
import XIcon from '../../assets/footer-images/x-icon.svg';

const FooterAr = () => {
  return (
    <footer className='footer arabic'>
      <div className='Footer-wrap'>
        <div className='footer-logo-wraper'>
          <a href='/ar/' className='footer-logo-link'><img src={FooterLogo} alt='Direction Logo' /></a>
          <p className='footer-text'>وكالة إبداعية <br className='' />مقرها القاهرة،<br className='' /> مصر</p>
        </div>
        <div className='footer-link-wrapper'>
          <a href='/ar/about'>من نحن</a>
          <a href='/ar/services'>خدماتنا</a>
          <a href='/ar/portfolio'>أعمالنا</a>
         </div>
         <div className='footer-social-wrapper'>
          <a href='https' className='footer-social-link'><img src={FBIcon} alt='Facebook' /></a>
          <a href='https' className='footer-social-link'><img src={InstaIcon} alt='Instagram' /></a>
          <a href='https' className='footer-social-link'><img src={LinkedinIcon} alt='LinkedIn' /></a>
          <a href='https' className='footer-social-link'><img src={XIcon} alt='Twitter' /></a>
         </div>
      </div>
    </footer>
  )
}

export default FooterAr