import React from 'react';
import './TopTechMasonry.css';
import ToptechLogo from '../../assets/portfolio-images/ToptechLogo.svg';
import ServicesOrngImg from '../../assets/homepage-images/dedicated-team.svg';
import DurationBlueIcon from '../../assets/portfolio-images/ClockBlue.svg';
import EgyptFlag from '../../assets/portfolio-images/EgyptFlag.svg';
import ToptechWebImg from '../../assets/portfolio-images/toptech-web.svg';

const TopTechMasonry = () => {
  return (
    <>
        <section className='toptech-section'>
            <div className='toptech-wrap width-90'>
                <h1 className='toptech-heading'>Top Tech</h1>
                <p className='toptech-txt'>Customized lead capturing system for B2B marketing campaigns.</p>
                <div className='toptech-card-covers'>
                    <div className='toptech-cardCol'>
                        <div className='top-logo-web-wrap'>
                            <div className='toptech-card-logo'><img src={ToptechLogo} alt='Top Tech' className='toptech-logo-img' /></div>
                            <div className='toptech-card-web-design hide-mb'>
                                <h4>Website</h4>
                                <img src={ToptechWebImg} alt='TopTech Website' />
                            </div>
                        </div>
                        <div className='top-dur-des-wrap'>
                            <div className='toptech-card-description'>
                                <h3>Toptech</h3>
                                <p>We crafted a high-converting landing page with a custom backend designed for intelligent lead capture and performance optimization, Toptech a leading technology vendor used our team to create a state of the art lead capturing landing page that won “campaign of the year” for its provider “Dell EMC”</p>
                            </div>
                            <div className='toptech-card-duration hide-mb'>
                                <img src={DurationBlueIcon} alt='Duration' className='toptech-duration-icon' />
                                <h4>Duration</h4>
                                <p>1 months</p>
                            </div>
                        </div>
                    </div>
                    <div className='duration-outer-wrap hide-dk'>
                        <div className='toptech-card-duration'>
                            <img src={DurationBlueIcon} alt='Duration' className='toptech-duration-icon' />
                            <h4>Duration</h4>
                            <p>1 months</p>
                        </div>
                        <div className='toptech-card-country'>
                            <h4>Country</h4>
                            <p>Egypt</p>
                            <img src={EgyptFlag} alt='Egypt' className='toptech-flag-icon' />
                        </div>
                    </div>
                    <div className='toptech-cardCol hide-mb'>
                        <div className='toptech-card-service'>
                            <img src={ServicesOrngImg} alt='Dedicate Team' className='toptech-services-img' />
                            <h4>Services</h4>
                            <p>Dedicate <br />Team</p>
                        </div>
                        <div className='toptech-card-country'>
                            <h4>Country</h4>
                            <p>Egypt</p>
                            <img src={EgyptFlag} alt='Egypt' className='toptech-flag-icon' />
                        </div>
                    </div>
                </div>
                <p className='toptech-website-wrap'>Visit Website: <a href='https://www.toptechegypt.com/' target='_blank'>www.toptechegypt.com</a></p>
            </div>
        </section>
    </>
  )
}

export default TopTechMasonry