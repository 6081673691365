import React from 'react';
import './ConcreteMasonry.css';
import ConcreteLogo from '../../assets/portfolio-images/ConcreteLogo.svg';
import ServicesBlueImg from '../../assets/homepage-images/product-solutions.svg';
import DurationBlueIcon from '../../assets/portfolio-images/ClockBlue.svg';
import EgyptFlag from '../../assets/portfolio-images/EgyptFlag.svg';
import ConcreteWeb from '../../assets/portfolio-images/concrete-web.svg';



const ConcreteMasonry = () => {
  return (
    <>
        <section className='concrete-section'>
            <div className='concrete-wrap width-90'>
                <h1 className='concrete-heading'>Concrete</h1>
                <p className='concrete-txt'>Customized portfolio website for one of the leading construction companies.</p>
                <div className='concrete-card-covers'>
                    <div className='concrete-cardCol'>
                        <div className='con-logo-serve-wrap'>
                            <div className='con-logo-head-wrap'>
                                <div className='concrete-card-logo'><img src={ConcreteLogo} alt='Concrete' className='concrete-logo-img' /></div>
                                <div className='concrete-card-des-heading hide-mb'><h3>Concrete</h3></div>
                            </div>
                            <div className='concrete-card-service hide-mb'>
                                <img src={ServicesBlueImg} alt='Product Development' className='concrete-services-img' />
                                <h4>Services</h4>
                                <p>Product <br />Development</p>
                            </div>
                        </div>
                        <div className='concrete-card-description'>
                            <p>Our product manager along with the design team and afterward the development team created a website for “Concrete” that effectively showcases the company's construction expertise, attracts new clients, and establishes brand credibility.</p>
                        </div>
                    </div>
                    <div className='concrete-cardCol'>
                        <div className='duration-outer-wrap'>
                            <div className='concrete-card-duration'>
                                <img src={DurationBlueIcon} alt='Duration' className='concrete-duration-icon' />
                                <h4>Duration</h4>
                                <p>15 months</p>
                            </div>
                            <div className='concrete-card-country'>
                                <img src={EgyptFlag} alt='Egypt' className='concrete-flag-icon' />
                                <h4>Country</h4>
                                <p>Egypt</p>
                            </div>
                        </div>
                        <div className='concrete-card-web-design hide-mb'>
                            <h4>Website</h4>
                            <img src={ConcreteWeb} alt='Concrete Website' />
                        </div>
                    </div>
                </div>
                <p className='concrete-website-wrap'>Visit Website: <a href='https://www.cec-concrete.com/' target='_blank'>www.cec-concrete.com</a></p>
            </div>
        </section>
    </>
  )
}

export default ConcreteMasonry