import React from 'react';
import './ConcreteMasonry.css';
import ConcreteLogo from '../../assets/portfolio-images/ConcreteLogo.svg';
import ServicesBlueImg from '../../assets/homepage-images/product-solutions.svg';
import DurationBlueIcon from '../../assets/portfolio-images/ClockBlue.svg';
import EgyptFlag from '../../assets/portfolio-images/EgyptFlag.svg';
import ConcreteWeb from '../../assets/portfolio-images/concrete-web.svg';

const ConcreteMasonryAr = () => {
  return (
    <>
        <section className='concrete-section'>
            <div className='concrete-wrap width-90'>
                <h1 className='concrete-heading'>كونكريت</h1>
                <p className='concrete-txt'>موقع تمثيلي لإحدى شركات المقاولات الرائدة في مصر.</p>
                <div className='concrete-card-covers'>
                    <div className='concrete-cardCol'>
                        <div className='con-logo-serve-wrap'>
                            <div className='con-logo-head-wrap'>
                                <div className='concrete-card-logo'><img src={ConcreteLogo} alt='Concrete' className='concrete-logo-img' /></div>
                                <div className='concrete-card-des-heading hide-mb'><h3>كونكريت</h3></div>
                            </div>
                            <div className='concrete-card-service hide-mb'>
                                <img src={ServicesBlueImg} alt='Product Development' className='concrete-services-img' />
                                <h4>الخدمة</h4>
                                <p>تطوير المنتجات</p>
                            </div>
                        </div>
                        <div className='concrete-card-description'>
                            <p>قام مدير المنتج لدينا جنبًا إلى جنب مع فريق التصميم وبعد ذلك فريق التطوير بإنشاء موقع ويب لـ "Concrete" الذي يعرض بشكل فعال خبرة الشركة في البناء، وجذب عملاء جدد، ويؤسس مصداقية العلامة التجارية. </p>
                        </div>
                    </div>
                    <div className='concrete-cardCol'>
                        <div className='duration-outer-wrap'>
                            <div className='concrete-card-duration'>
                                <img src={DurationBlueIcon} alt='Duration' className='concrete-duration-icon' />
                                <h4>المدة</h4>
                                <p>15 أشهر</p>
                            </div>
                            <div className='concrete-card-country'>
                                <img src={EgyptFlag} alt='Egypt' className='concrete-flag-icon' />
                                <h4>الدولة</h4>
                                <p>مصر</p>
                            </div>
                        </div>
                        <div className='concrete-card-web-design hide-mb'>
                            <h4>موقع إلكتروني</h4>
                            <img src={ConcreteWeb} alt='Concrete Website' />
                        </div>
                    </div>
                </div>
                <p className='concrete-website-wrap'>Visit Website: <a href='https://www.cec-concrete.com/' target='_blank'>www.cec-concrete.com</a></p>
            </div>
        </section>
    </>
  )
}

export default ConcreteMasonryAr