import React from 'react';

export const CustomMenuBtn = ({menuOpen}) => {
  
  return (
      <div className={`custom-menu-btn hide-dk ${menuOpen ? 'open' : ''}`}>
          <span className='cus-menu-spn1'></span>
          <span className='cus-menu-spn2'></span>
          <span className='cus-menu-spn3'></span>
          <span className='cus-menu-spn4'></span>
          <span className='cus-menu-spn5'></span>
          <span className='cus-menu-spn6'></span>
      </div>
  )
}

export default CustomMenuBtn
