import React, {useState} from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import HeaderLogo from '../../assets/homepage-images/Nav-logo.svg';
// import DirMenu from '../../assets/homepage-images/direction-logo-blue.svg';
import CustomMenuBtn from './CustomMenuBtn';
import {Routes, useLocation, useSearchParams } from "react-router-dom";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const navigateArabic = function (e){
    e.preventDefault();
    window.location.href = '/ar'+location.pathname;
  }

  return (
    <header className='header'>
      <nav className='direction-navbar'>
        <div className='direction-logo-cover'>
          <div className='logo-wraper'>
            <a href='/' className='logo-link'><img src={HeaderLogo} alt='Direction Logo' /></a>
          </div>
        </div>
        <div className={`nav-menu hide-dk`} onClick={() => {setMenuOpen(!menuOpen)}}>
          <CustomMenuBtn menuOpen={menuOpen}/>
          {/* <div className='menu-wrap'><img src={DirMenu} alt='Menu Icon' className='direction-menu' /></div> */}
        </div>
        <div className={`direction-nav-cover ${menuOpen ? 'open' : ''}`}>
          <div className='nav-inner-wrapper'>
            <a href='/' className={'btn '+(location.pathname == "/"?'selected':' ')}>Home</a>
            <a href='/about' className={'btn '+(location.pathname == "/about"?'selected':' ')}>About us</a>
            <a href='/services' className={'btn '+(location.pathname == "/services"?'selected':' ')}>Services</a>
            <a href='/portfolio' className={'btn '+(location.pathname == "/portfolio"?'selected':' ')}>Portfolio</a>
            <a href='/case-studies' className={'btn '+(location.pathname == "/case-studies"?'selected':' ')}>Case Studies</a>
          </div>
          <div className='nav-btn-wrap'>
            <a href='#' className='btn language-btn' onClick={navigateArabic}>عربي</a>
            <a href='/services' className='btn start-now-nav'>Start Now</a>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header