import React from 'react';
import UndrCardImg1 from '../../assets/aboutpage-images/understand-card-img1.svg';
import UndrCardImg2 from '../../assets/aboutpage-images/understand-card-img2.svg';
import UndrCardImg3 from '../../assets/aboutpage-images/understand-card-img3.svg';
import ChooseCardIcon1 from '../../assets/aboutpage-images/experties.svg';
import ChooseCardIcon2 from '../../assets/aboutpage-images/entrepreneurial.svg';
import ChooseCardIcon3 from '../../assets/aboutpage-images/global.svg';
import ChooseCardIcon4 from '../../assets/aboutpage-images/DirectionIconBlack.svg';
import ChooseCardIcon5 from '../../assets/aboutpage-images/Approach.svg';
import officeMap from '../../assets/homepage-images/offices-map.svg';
import officeListImg1 from '../../assets/homepage-images/list-logo1.svg';
import officeListImg2 from '../../assets/homepage-images/list-logo2.svg';
import officeListImg3 from '../../assets/homepage-images/list-logo3.svg';
import officeListImg4 from '../../assets/homepage-images/list-logo4.svg';



const Aboutpage = () => {
    return (
        <div className='aboutpage'>
            <section className='about-hero-section'>
                <div className='abt-hero-wrap'>
                    <h1 className='abt-hero-heading'>Empowering Startups with <br className='hide-mb' />Expertise & Passion</h1>
                    <p className='abt-hero-txt'><span className='abt-hero-txt-spn'>Direction North</span> is your trusted partner in navigating the ever-evolving tech landscape. As a development house with <span className='abt-hero-txt-spn'>11+ years of experience</span>, we specialize in helping startups like yours <span className='abt-hero-txt-spn'>outsource their tech stack</span> and <span className='abt-hero-txt-spn'>build innovative products</span> that drive meaningful growth.</p>
                </div>
            </section>
            <section className='understand-section'>
                <div className='understand-wrap'>
                    <div className='understand-card heading'><h2 className='understart-heading'>We understand your challenges:</h2></div>
                    <div className='understand-card bg-white'>
                        <div className='undr-card-txt-wrap'>
                            <h3 className='undr-card-heading'>Limited in-house <span className='undr-card-spn'>Tech Expertise?</span></h3>
                            <p className='undr-card-txt'>No sweat! We provide comprehensive solutions, from web/app development to custom software and seamless Meta product integrations.</p>
                        </div>
                        <div className='undr-card-img-wrap'><img src={UndrCardImg1} alt='Tech Expertise' className='undr-card-img' /></div>
                    </div>
                    <div className='understand-card bg-white'>
                        <div className='undr-card-txt-wrap'>
                            <h3 className='undr-card-heading'>Concerned about <br className='' /><span className='undr-card-spn'>Scalability</span> and future-proofing?</h3>
                            <p className='undr-card-txt'>We craft solutions that grow with you, leveraging cutting-edge technologies and best practices.</p>
                        </div>
                        <div className='undr-card-img-wrap'><img src={UndrCardImg2} alt='Scalability' className='undr-card-img' /></div>
                    </div>
                    <div className='understand-card bg-white'>
                        <div className='undr-card-txt-wrap'>
                            <h3 className='undr-card-heading'>Feeling lost in the <span className='undr-card-spn'>startup</span> jungle?</h3>
                            <p className='undr-card-txt'>We've been there! Our entrepreneurial leadership team empathizes with your pain points and offers invaluable guidance.</p>
                        </div>
                        <div className='undr-card-img-wrap'><img src={UndrCardImg3} alt='Startup' className='undr-card-img' /></div>
                    </div>
                </div>
            </section>
            <section className='choose-section'>
                <div className='choose-wrapper'>
                    <div className='choose-col heading'>
                        <div className='choose-card'><h4 className='choose-heading'>Why Choose <br className='' />Direction <br className='' />North?</h4></div>
                    </div>
                    <div className='choose-col'>
                        <div className='choose-card green'>
                            <div className='choose-card-data-wrap'>
                                <div className='choose-card-head-wrap'>
                                        <img src={ChooseCardIcon1} alt='Deep Expertise' className='choose-card-icon' />
                                        <h5 className='choose-card-heading'>Deep <br />Expertise</h5>
                                    </div>
                                <p className='choose-card-txt'>Our team of skilled developers possesses proven expertise in various technologies, ensuring your project's success.</p>
                            </div>
                        </div>
                    </div>
                    <div className='choose-col'>
                        <div className='choose-card orange'>
                            <div className='choose-card-data-wrap'>
                                <div className='choose-card-head-wrap'>
                                        <img src={ChooseCardIcon2} alt='Entrepreneurial Spirit' className='choose-card-icon' />
                                        <h5 className='choose-card-heading'>Entrepreneurial Spirit</h5>
                                    </div>
                                <p className='choose-card-txt'>We understand your startup journey and speak your language. We're not just developers, we're your growth partners.</p>
                            </div>
                        </div>
                    </div>
                    <div className='choose-col'>
                        <div className='choose-card blue'>
                            <div className='choose-card-data-wrap'>
                                <div className='choose-card-head-wrap'>
                                        <img src={ChooseCardIcon3} alt='Global Reach' className='choose-card-icon' />
                                        <h5 className='choose-card-heading'>Global <br />Reach</h5>
                                    </div>
                                <p className='choose-card-txt'>With offices in Cairo, Riyadh, and Islamabad, we offer scalability and diverse perspectives to cater to your evolving needs.</p>
                            </div>
                        </div>
                    </div>
                    <div className='choose-col'>
                        <div className='choose-card grey'>
                            <div className='choose-card-inner'>
                                <img src={ChooseCardIcon4} alt='Direction Icon' className='choose-direction-icon' />
                            </div>
                        </div>
                    </div>
                    <div className='choose-col'>
                        <div className='choose-card yellow'>
                            <div className='choose-card-data-wrap'>
                                <div className='choose-card-head-wrap'>
                                        <img src={ChooseCardIcon5} alt='Customer-Centric Approach' className='choose-card-icon' />
                                        <h5 className='choose-card-heading'>Customer-Centric Approach</h5>
                                    </div>
                                <p className='choose-card-txt'>We collaborate closely with you, prioritizing your vision and ensuring transparency throughout the entire development process.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='about office-section'>
                <div className='about office-wrapper'>
                    <div className='office-txt-wrap'>
                        <h4 className='office-heading'>Offices</h4>
                        <p className='office-txt'>Just around the corner</p>
                        <ul className='offices-list'>
                            <li><span className='offices-li-spn'><img src={officeListImg1} alt='Cairo' /></span>Cairo</li>
                            <li><span className='offices-li-spn'><img src={officeListImg2} alt='Riyadh' /></span>Riyadh</li>
                            <li><span className='offices-li-spn'><img src={officeListImg3} alt='Islamabad' /></span>Islamabad</li>
                            <li><span className='offices-li-spn'><img src={officeListImg4} alt='United states' /></span>United states</li>
                        </ul>
                    </div>
                    <div className='office-img-wrap'><img src={officeMap} alt='' className='office-map-img' /></div>
                </div>
            </section>
            <section className='journey-section'>
                <div className='journey-wrap'>
                    <div className='journey-txt-wrap'>
                        <h4 className='journey-heading'>Ready to <span className='jpurney-head-spn'>Embark on your tech<br className='hide-mb' /> journey</span> with confidence?<br className='' /></h4>
                        <p className='journey-txt'>Contact us today and let's discuss how Direction North can help <br className='hide-mb' />you achieve your ambitious goals.</p>
                    </div>
                    <div className='journey-btn-wrap'>
                        <a href='/services' className='start-now-btn'>Start now</a>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Aboutpage