import React from 'react';
import Aboutpage from '../components/Aboutpage/Aboutpage.js'

const About = () => {
    return (
        <>
             <Aboutpage />
        </>
    )
}

export default About