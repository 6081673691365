import React from 'react'
import CaseStudiespage from '../components/CaseStudiespage/CaseStudiespage';
import CaseStudiespageAr from '../components/CaseStudiespage/CaseStudiespageAr';

const studies = () => {
  return (
    <CaseStudiespageAr />
  )
}

export default studies