import React from 'react';
import './ClinicMasonry.css';
import ClinicLogo from '../../assets/portfolio-images/ClinicUnlockLogoAr.svg';
import ServicesOrngImg from '../../assets/homepage-images/dedicated-team.svg';
import DurationOrngIcon from '../../assets/portfolio-images/ClockOrng.svg';
import ArabFlag from '../../assets/portfolio-images/ArabFlag.svg';
import PlayStore from '../../assets/portfolio-images/PlayStoreWhite.svg';
import AppleStore from '../../assets/portfolio-images/AppleStoreWhite.svg';
import ClinicAppImg from '../../assets/portfolio-images/clinic-app-img.svg';
import ClinicWebImg from '../../assets/portfolio-images/clinic-web-img-ar.svg';

const ClinicMasonryAr = () => {
  return (
    <>
        <section className='clinic-section'>
            <div className='clinic-wrap width-90'>
                <h1 className='clinic-heading'>كلينيك أنلوك</h1>
                <p className='clinic-txt'>برنامج تحسين أداء العيادات و المنشأت الطبية في المملكة العربية السعودية عن طريق زياده المبيعات وتقليل التكاليف  .</p>
                <div className='clinic-card-covers'>
                    <div className='clinic-cardCol'>
                        <div className='cl-sub-cardCol'>
                            <div className='clinic-log-dur-wrap'>
                                <div className='clinic-card-logo'><img src={ClinicLogo} alt='Clinic Unlock' className='clinic-logo-img' /></div>
                                <div className='clinic-card-duration hide-mb'>
                                    <img src={DurationOrngIcon} alt='Duration' className='clinic-duration-icon' />
                                    <h4>المدة</h4>
                                    <p>4 شهور</p>
                                </div>
                            </div>
                            <div className='duration-outer-wrap hide-dk'>
                                <div className='clinic-card-duration'>
                                    <img src={DurationOrngIcon} alt='Duration' className='clinic-duration-icon' />
                                    <h4>المدة</h4>
                                    <p>4 شهور</p>
                                </div>
                                <div className='clinic-card-country'>
                                    <img src={ArabFlag} alt='Saudi Arabia' className='clinic-flag-icon' />
                                    <h4>الدولة</h4>
                                    <p>المملكة العربية السعودية</p>
                                </div>
                            </div>
                            <div className='clinic-card-description'>
                                <h3>كلينيك أنلوك</h3>
                                <p>ساعد فريقنا شركة كلينك أنلوك على إنشاء منتجهم خلالv 4 أشهر فقط، مع الرغم من تحدي و تعقيد المنتج اطلقنا المنتج وأصبح المنتج من أهم منتجات تحسين أداء العيادة في المملكة. يعمل نظام الإدارة المبتكر على تمكين العيادات والأطباء من تعزيز تجربة المرضى وتقليل حالات عدم الحضور.</p>
                            </div>
                        </div>
                        <div className='cl-web-country-wrap hide-mb'>
                            <div className='clinic-card-web-design'>
                                <h4>موقع إلكتروني</h4>
                                <p>خبراء تصميم واجهة <br />وتجربة المستخدم</p>
                                <img src={ClinicWebImg} alt='Clinic Unlock Website' />
                            </div>
                            <div className='clinic-card-country'>
                                <img src={ArabFlag} alt='Saudi Arabia' className='clinic-flag-icon' />
                                <h4>الدولة</h4>
                                <p>المملكة العربية السعودية</p>
                            </div>
                        </div>
                    </div>
                    <div className='clinic-cardCol hide-mb'>
                        <div className='clinic-card-service'>
                            <h4>المدةالخدمة</h4>
                            <p>توفير المواهب التقنية.</p>
                            <img src={ServicesOrngImg} alt='Dedicate Team' className='clinic-services-img' />
                        </div>
                        <div className='clinic-card-app'>
                            <h4>تطبيق الموبايل</h4>
                            <div className='clinic-appstore-wrap'>
                                <a href='https' target='_blank'><img src={PlayStore} alt='Play Store' /></a>
                                <a href='https' target='_blank'><img src={AppleStore} alt='Apple Store' /></a>
                            </div>
                            <div className='clinic-app-card-img-wrap'><img src={ClinicAppImg} alt='Clinic Unlock App' className='' /></div>
                        </div>
                    </div>
                </div>
                <p className='clinic-website-wrap'>Visit Website: <a href='https://www.clinicunlock.com/' target='_blank'>www.clinicunlock.com</a></p>
            </div>
        </section>
    </>
  )
}

export default ClinicMasonryAr