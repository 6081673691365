import React from 'react'
import DirectionLogo from '../../assets/homepage-images/direction-logo-blue.svg';
// import WhatWeDoImage1 from '../../assets/homepage-images/product-solutions.svg';
// import WhatWeDoImage2 from '../../assets/homepage-images/dedicated-team.svg';
import WhatWeDoImage3 from '../../assets/homepage-images/dedicated-team1.svg';
import WhatWeDoImage4 from '../../assets/homepage-images/product-solutions1.svg';
import ArrowOrng from '../../assets/homepage-images/orange-arrow.svg';
import craftCardIcon1 from '../../assets/homepage-images/customized-software.svg';
import craftCardIcon2 from '../../assets/homepage-images/whatsapp-icon.svg';
import craftCardIcon3 from '../../assets/homepage-images/web-development.svg';
// import craftDot from '../../assets/homepage-images/green-dot.svg';
import craftArrow from '../../assets/homepage-images/arrow-right-icon.svg';
import trustedCompany1 from '../../assets/homepage-images/company-logo1.svg';
import trustedCompany2 from '../../assets/homepage-images/company-logo2.svg';
import trustedCompany3 from '../../assets/homepage-images/company-logo3.svg';
import officeMap from '../../assets/homepage-images/offices-map.svg';
import officeListImg1 from '../../assets/homepage-images/list-logo1.svg';
import officeListImg2 from '../../assets/homepage-images/list-logo2.svg';
import officeListImg3 from '../../assets/homepage-images/list-logo3.svg';
import officeListImg4 from '../../assets/homepage-images/list-logo4.svg';


const Homepage = () => {
    return (
        <div className='homepage'>
            <section className='hero-section'>
                <div className='hero-wrapper'>
                    <div className='hero-inner-wrap'>
                        <h1 className='hero-heading'><div className='hero-heading-spn'>Building & Scaling Solutions </div>For Aspiring Startups</h1>
                        <div className='hero-img-wrap'><img src={DirectionLogo} alt='Direction Logo' className='hero-logo' /><p>Beyond code, beyond expectation: We deliver game-changing development for ambitious digital startups.</p></div>
                    </div>
                </div>
            </section>
            <section className='intro-section'>
                <div className='intro-wrapper'>
                    <div className='intro-heading-wrap'><h2 className='intro-heading'>What we do</h2></div>
                    <div className='intro-inner-wrapper'>
                        <div className='what-we-do-card'>
                            <div className='wedo-card-head-wrap'>
                                <h3 className='what-we-do-heading'>Product <br />Development <br />Solutions</h3>
                                <img src={WhatWeDoImage4} alt='Product Developmet Solution' className='what-we-do-img' />
                            </div>
                            <p className='what-we-do-txt'><b>Transform your product vision into reality</b> with our specialized experts. We handle the entire process, from ideation to launch, so you can focus on what matters most.</p>
                            <div className='wedo-card-link-wrap'><a href='/services'>Know more <span><img src= {ArrowOrng} alt='Arrow' /></span></a></div>
                        </div>
                        <div className='what-we-do-card'>
                            <div className='wedo-card-head-wrap'>
                                <h3 className='what-we-do-heading'>Dedicated <br />Team of <br />Experts</h3>
                                <img src={WhatWeDoImage3} alt='Dedicated Team of Experts' className='what-we-do-img' />
                            </div>
                            <p className='what-we-do-txt'><b>We help you find the right candidates</b> based on your development requirements, where you can directly manage your team with flexible contracts starting from 3 months till 1 year.</p>
                            <div className='wedo-card-link-wrap'><a href='/services'>Know more <span><img src= {ArrowOrng} alt='Arrow' /></span></a></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='craft-section'>
                <div className='craft-main-cover'>
                    <div className='craft-heading-wrap'><h4 className='craft-heading'>Masters of the Craft <span className='craft-heading-spn'>in</span></h4></div>
                    <div className='craft-wrapper'>
                        <div className='craft-inner-wrap left'>
                            
                            <div className='craft-card orange'>
                                <div className='craft-card-icon-wrap'>
                                    <img src={craftCardIcon1} alt='Customized Software Development' className='craft-card-icon' />
                                    <h4 className='craft-card-heading'>Customized Software Development</h4>
                                </div>
                                <div className='craft-card-text-wrap'>
                                    {/* <img src={craftDot} alt='Dot Icon' className='craft-dot-icon' /> */}
                                    <p className='craft-card-txt'>In the fast-paced world of startups, having the right tools is crucial for survival and success. While off-the-shelf software might seem like a quick fix, it often lacks the flexibility and personalization that a young company needs. This is where customized software development comes in. We have a variety of customization options that will make your company shine.</p>
                                    <div className='craft-link-wrap'><a href='/case-studies#CustomizedDevelopment' target='_blank' className='craft-card-link'>Explore <img src={craftArrow} alt='Arrow Icon' /></a></div>
                                </div>
                            </div>
                        </div>
                        <div className='craft-inner-wrap right'>
                            <div className='craft-card green'>
                                <div className='craft-card-icon-wrap'><img src={craftCardIcon2} alt='Whatsapp API Integrations' className='craft-card-icon' /></div>
                                <div className='craft-card-text-wrap'>
                                    {/* <img src={craftDot} alt='Dot Icon' className='craft-dot-icon' /> */}
                                    <h4 className='craft-card-heading'>Whatsapp API Integrations</h4>
                                    <p className='craft-card-txt'>After developing dozens of integrations with Meta products and specifically with WhatsApp Business API, we know how to empower your systems with a most powerful communication channel to connect with billions of users on their preferred platform. </p>
                                    <div className='craft-link-wrap'><a href='/case-studies#WhatsappAPI' target='_blank' className='craft-card-link'>Explore <img src={craftArrow} alt='Arrow Icon' /></a></div>
                                </div>
                            </div>
                            <div className='craft-card black'>
                                <div className='craft-card-icon-wrap'><img src={craftCardIcon3} alt='Web/App Product Development' className='craft-card-icon' /></div>
                                <div className='craft-card-text-wrap'>
                                    {/* <img src={craftDot} alt='Dot Icon' className='craft-dot-icon' /> */}
                                    <h4 className='craft-card-heading'>Web/App Product Development</h4>
                                    <p className='craft-card-txt'>We help visionary entrepreneurs create the products of their dreams, an from UI/UX enhancement, planning and technical development and even code customization; we have been through it all and will save you valuable time and resources. </p>
                                    <div className='craft-link-wrap'><a href='/case-studies#TeamTechOutsourcing' target='_blank' className='craft-card-link'>Explore <img src={craftArrow} alt='Arrow Icon' /></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='boundaries-section'>
                <div className='boundaries-wrapper'>
                    <h5 className='bound-sub-heading'>We don’t play it safe,</h5>
                    <h4 className='bound-heading'>We Push Boundaries</h4>
                    <div className='bound-inn-wrap'>
                        <div className='bound-col'>
                            <h5 className='bound-col-heading'>+11 Years</h5>
                            <p className='bound-col-txt'>of operations</p>
                        </div>
                        <div className='bound-col'>
                            <p className='bound-col-txt'>More than</p>
                            <h5 className='bound-col-heading'>100 Clients </h5>
                        </div>
                        <div className='bound-col'>
                            <p className='bound-col-txt'>In</p>
                            <h5 className='bound-col-heading'>5 Countries</h5>
                        </div>
                    </div>
                </div>
            </section>
            <section className='trusted-section'>
                <div className='trusted-wrap'>
                    <div className='trusted-inn-wrap'>
                        <h4 className='trusted-heading'><span className='trusted-head-spn'>Trusted</span> and supported by</h4>
                        <div className='trusted-col-wrap'>
                            <div className='trusted-col'><img src={trustedCompany1} alt='Taqadam' className='trusted-img' /></div>
                            <div className='trusted-col'><img src={trustedCompany2} alt='Misk Accelerator' className='trusted-img' /></div>
                            <div className='trusted-col'><img src={trustedCompany3} alt='Inspire U' className='trusted-img' /></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='office-section'>
                <div className='office-wrapper'>
                    <div className='office-txt-wrap'>
                        <h4 className='office-heading'>Our<br className='' />Workspaces</h4>
                        <ul className='offices-list'>
                            <li><span className='offices-li-spn'><img src={officeListImg1} alt='Cairo' /></span>Cairo</li>
                            <li><span className='offices-li-spn'><img src={officeListImg2} alt='Riyadh' /></span>Riyadh</li>
                            <li><span className='offices-li-spn'><img src={officeListImg3} alt='Islamabad' /></span>Islamabad</li>
                            <li><span className='offices-li-spn'><img src={officeListImg4} alt='United states' /></span>United states</li>
                        </ul>
                    </div>
                    <div className='office-img-wrap'><img src={officeMap} alt='' className='office-map-img' /></div>
                </div>
            </section>
            <section className='challange-section'>
                <div className='challenge-wrap'>
                    <div className='challenge-head-wrap'>
                        <h4 className='challenge-main-heading'><span className='challenge-head-spn'>Think your project</span><br className='' />is challenging? </h4>
                        <h5 className='challenge-sub-heading'>Let's prove<br className='' /> you wrong</h5>
                    </div>
                    <div className='challenge-btn-wrap'>
                        <a href='/services' className='start-now-btn'>Start now</a>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Homepage