import React from 'react';
import HomepageAr from '../components/Homepage/HomepageAr';

const Home = () => {
    return (
        <>
            <HomepageAr />
        </>
    )
}

export default Home