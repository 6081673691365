import React from 'react';
import DirectionLogo from '../../assets/homepage-images/direction-logo-blue.svg';
// import WhatWeDoImage1 from '../../assets/homepage-images/product-solutions.svg';
// import WhatWeDoImage2 from '../../assets/homepage-images/dedicated-team.svg';
import WhatWeDoImage3 from '../../assets/homepage-images/dedicated-team1.svg';
import WhatWeDoImage4 from '../../assets/homepage-images/product-solutions1.svg';
import ArrowOrng from '../../assets/homepage-images/orange-arrow-ar.svg';
import craftCardIcon1 from '../../assets/homepage-images/customized-software.svg';
import craftCardIcon2 from '../../assets/homepage-images/whatsapp-icon.svg';
import craftCardIcon3 from '../../assets/homepage-images/web-development.svg';
// import craftDot from '../../assets/homepage-images/green-dot.svg';
import craftArrow from '../../assets/homepage-images/arrow-right-icon-ar.svg';
import trustedCompany1 from '../../assets/homepage-images/company-logo1.svg';
import trustedCompany2 from '../../assets/homepage-images/company-logo2.svg';
import trustedCompany3 from '../../assets/homepage-images/company-logo3.svg';
import officeMap from '../../assets/homepage-images/offices-map.svg';
import officeListImg1 from '../../assets/homepage-images/list-logo1.svg';
import officeListImg2 from '../../assets/homepage-images/list-logo2.svg';
import officeListImg3 from '../../assets/homepage-images/list-logo3.svg';
import officeListImg4 from '../../assets/homepage-images/list-logo4.svg';

const HomepageAr = () => {
  return (
    <div className='homepage arabic'>
            <section className='hero-section'>
                <div className='hero-wrapper'>
                    <div className='hero-inner-wrap'>
                        <h1 className='hero-heading'><div className='hero-heading-spn'>نبني ونطور منتجات </div>رقمية للشركات الطموحة</h1>
                        <div className='hero-img-wrap'><img src={DirectionLogo} alt='Direction Logo' className='hero-logo' /><p>نقدم ما  هو أبعد من مجرد الخدمات البرمجية، تتخطى التوقعات لتواكب طموحات الشركات الصاعدة</p></div>
                    </div>
                </div>
            </section>
            <section className='intro-section'>
                <div className='intro-wrapper'>
                    <div className='intro-heading-wrap'><h2 className='intro-heading'>ما الذي نقدمه لشركتك</h2></div>
                    <div className='intro-inner-wrapper'>
                        <div className='what-we-do-card'>
                            <div className='wedo-card-head-wrap'>
                                <h3 className='what-we-do-heading'>تطوير <br />مــنــتــجـــــــات <br />وحلول رقمية</h3>
                                <img src={WhatWeDoImage4} alt='Product Developmet Solution' className='what-we-do-img' />
                            </div>
                            <p className='what-we-do-txt'><b>منتجك الرقمي ليس مجرد فكرة!</b> نحن نتكفل بنقل رؤيتك عن منتجك الرقمي إلى حقيقة مع فريق من الخبراء المتخصصين. ندير عملية بناء منتجك بالكامل ليصبح لديك الوقت الكافي لإدارة باقي مهام الشركة .</p>
                            <div className='wedo-card-link-wrap'><a href='/ar/services'>اعرف المزيد <span><img src= {ArrowOrng} alt='Arrow' /></span></a></div>
                        </div>
                        <div className='what-we-do-card'>
                            <div className='wedo-card-head-wrap'>
                                <h3 className='what-we-do-heading'>تقديم فريق <br />عــمــل مــن <br />المطوريــــن</h3>
                                <img src={WhatWeDoImage3} alt='Dedicated Team of Experts' className='what-we-do-img' />
                            </div>
                            <p className='what-we-do-txt'><b>نجاح كل مشروع يعتمد على فريق التنفيذ.</b> لذلك نقدم لك فريق متكامل يتولى مسؤولية التطوير. من خلال عقد عمل يمتاز بالمرونة، يمكنك إدارة الفريق والمشروع مباشرة لفترة مؤقتة تمتد من 3 شهور إلى سنة قابلة للتجديد، تعتمد على عدد الساعات التي يتطلبها المشروع أسبوعيًا.</p>
                            <div className='wedo-card-link-wrap'><a href='/ar/services'>اعرف المزيد <span><img src= {ArrowOrng} alt='Arrow' /></span></a></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='craft-section'>
                <div className='craft-main-cover'>
                    <div className='craft-heading-wrap'><h4 className='craft-heading mb-5'>متخصصين ومحترفين في</h4></div>
                    <div className='craft-wrapper'>
                        <div className='craft-inner-wrap left'>
                            
                            <div className='craft-card orange'>
                                <div className='craft-card-icon-wrap'>
                                    <img src={craftCardIcon1} alt='Customized Software Development' className='craft-card-icon' />
                                    <h4 className='craft-card-heading'>توفير المواهب التقنية المميزة</h4>
                                </div>
                                <div className='craft-card-text-wrap'>
                                    {/* <img src={craftDot} alt='Dot Icon' className='craft-dot-icon' /> */}
                                    <p className='craft-card-txt'>في عالم يغلبه السرعة والمتغيرات، النجاح والاستمرارية يكون من نصيب كل شركة لديها الأدوات الملائمة. البرمجيات المطورة مسبقًا قد تكون حل مؤقت إلى كثير من الشركات، إلا أنها تفتقد إلى المرونة والهوية الفريدة التي تحتاجها الشركات الناشئة. من هنا يظهر تطوير البرامج المخصصة! لدينا خطط متنوعة للتطوير تصل بشركتك إلى صفوف الشركات المؤثرة محليًا وعالميًا.</p>
                                    <div className='craft-link-wrap'><a href='/ar/case-studies#CustomizedDevelopment' target='_blank' className='craft-card-link'>إعرف أكتر <img src={craftArrow} alt='Arrow Icon' /></a></div>
                                </div>
                            </div>
                        </div>
                        <div className='craft-inner-wrap right'>
                            <div className='craft-card green'>
                                <div className='craft-card-icon-wrap'><img src={craftCardIcon2} alt='Whatsapp API Integrations' className='craft-card-icon' /></div>
                                <div className='craft-card-text-wrap'>
                                    {/* <img src={craftDot} alt='Dot Icon' className='craft-dot-icon' /> */}
                                    <h4 className='craft-card-heading'>دمج نظامك مع أي تطبيق آخر عبر تقنية الـ API</h4>
                                    <p className='craft-card-txt'>بعد تطوير عشرات البرامج  و ربطها بالأنظمة المختلفة، وخاصة منتجات Meta وWhatsApp Business API، أصبحنا خبراء في إتمام و تفعيل الربط وإيصالك لمستخدمينك على القنوات المفضلة لديهم.</p>
                                    <div className='craft-link-wrap'><a href='/ar/case-studies#WhatsappAPI' target='_blank' className='craft-card-link'>إعرف أكتر <img src={craftArrow} alt='Arrow Icon' /></a></div>
                                </div>
                            </div>
                            <div className='craft-card black'>
                                <div className='craft-card-icon-wrap'><img src={craftCardIcon3} alt='Web/App Product Development' className='craft-card-icon' /></div>
                                <div className='craft-card-text-wrap'>
                                    {/* <img src={craftDot} alt='Dot Icon' className='craft-dot-icon' /> */}
                                    <h4 className='craft-card-heading'>تطوير المنتجات الرقمية</h4>
                                    <p className='craft-card-txt'>نحن نساعد رواد الأعمال ذوي الرؤى على إنشاء منتجات أحلامهم، بدءًا من تحسين واجهة المستخدم/تجربة المستخدم والتخطيط والتطوير التقني؛ لقد مررنا بكل ذلك في مرات عديدة وسنوفر لك وقتًا وموارد ثمينة.</p>
                                    <div className='craft-link-wrap'><a href='/ar/case-studies#TeamTechOutsourcing' target='_blank' className='craft-card-link'>إعرف أكتر <img src={craftArrow} alt='Arrow Icon' /></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='boundaries-section'>
                <div className='boundaries-wrapper'>
                    {/* <h5 className='bound-sub-heading'>We don’t play it safe,</h5> */}
                    <h4 className='bound-heading'>طموحنا يتجاوز التوقعات</h4>
                    <div className='bound-inn-wrap'>
                        <div className='bound-col'>
                            <h5 className='bound-col-heading'>+11 عام</h5>
                            <p className='bound-col-txt'> من عمليات التشغيل</p>
                        </div>
                        <div className='bound-col'>
                            <p className='bound-col-txt'>وأكثـر</p>
                            <h5 className='bound-col-heading'>100 عميل</h5>
                        </div>
                        <div className='bound-col'>
                            <p className='bound-col-txt'>متواجدين في</p>
                            <h5 className='bound-col-heading'>5 دول</h5>
                        </div>
                    </div>
                </div>
            </section>
            <section className='trusted-section'>
                <div className='trusted-wrap'>
                    <div className='trusted-inn-wrap'>
                        <h4 className='trusted-heading'>كسبنا دعم وثقة</h4>
                        <div className='trusted-col-wrap'>
                            <div className='trusted-col'><img src={trustedCompany1} alt='Taqadam' className='trusted-img' /></div>
                            <div className='trusted-col'><img src={trustedCompany2} alt='Misk Accelerator' className='trusted-img' /></div>
                            <div className='trusted-col'><img src={trustedCompany3} alt='Inspire U' className='trusted-img' /></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='office-section'>
                <div className='office-wrapper'>
                    <div className='office-txt-wrap'>
                        <h4 className='office-heading mb-4'>متواجدين في</h4>
                        <ul className='offices-list'>
                            <li><span className='offices-li-spn'><img src={officeListImg1} alt='Cairo' /></span>القاهرة</li>
                            <li><span className='offices-li-spn'><img src={officeListImg2} alt='Riyadh' /></span>الرياض</li>
                            <li><span className='offices-li-spn'><img src={officeListImg3} alt='Islamabad' /></span>إسلام آباد</li>
                            <li><span className='offices-li-spn'><img src={officeListImg4} alt='United states' /></span>الولايات المتحدة الأمريكية</li>
                        </ul>
                    </div>
                    <div className='office-img-wrap'><img src={officeMap} alt='' className='office-map-img' /></div>
                </div>
            </section>
            <section className='challange-section'>
                <div className='challenge-wrap'>
                    <div className='challenge-head-wrap'>
                        <h4 className='challenge-main-heading'><span className='challenge-head-spn'>هل مشروعك يمثل تحديًا؟</span></h4>
                        <h4 className='challenge-main-heading'><span className='challenge-head-spn fw-normal'>دعونا نثبت أن يمكننا تخطيه معاً</span></h4>
                    </div>
                    <div className='challenge-btn-wrap'>
                        <a href='/ar/services' className='start-now-btn'>ابدأ الآن</a>
                    </div>
                </div>
            </section>
        </div>
  )
}

export default HomepageAr