import React from "react";
import Servicespage from "../components/Servicespage/Servicespage";
import ServicespageAr from "../components/Servicespage/ServicespageAr";


const Services = () => {
    return (
        <>
            <ServicespageAr />
            {/* <Servicespage /> */}
        </>
    )
}

export default Services