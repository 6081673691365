import React from "react";
import Servicespage from "../components/Servicespage/Servicespage";


const Services = () => {
    return (
        <>
             <Servicespage />
        </>
    )
}

export default Services