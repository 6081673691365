import React from 'react'
import Portfoliopage from '../components/Portfoliopage/Portfoliopage'

const portfolio = () => {
  return (
    <>
        <Portfoliopage />
    </>
  )
}

export default portfolio