import React from 'react';
import FlashleadMasonry from './FlashleadMasonry';
import ClinicMasonry from './ClinicMasonry';
import ConcreteMasonry from './ConcreteMasonry';
import EgentMasonry from './EgentMasonry';
import APIMasonry from './APIMasonry';
import TopTechMasonry from './TopTechMasonry';


const Portfoliopage = () => {
  return (
    <div className='portfolio-page'>
        <FlashleadMasonry />
        <ClinicMasonry />
        <ConcreteMasonry />
        <EgentMasonry />
        <TopTechMasonry />
        <APIMasonry />
    </div>
  )
}

export default Portfoliopage