import React from 'react';

const thankyou = () => {
  return (
    <>
        <div className='thankyou-page arabic'>
            <div className='thankyou-cover'>
                <h1>شكرا لاستكمال التسجيل</h1>
                <p>سيتصل بك أحد مستشارينا خلال الـ 48 ساعة القادمة</p>
                <a href='/ar/'>العودة إلى الصفحة الرئيسية</a>
            </div>
        </div>
    </>
  )
}

export default thankyou