import React from 'react';
import HeroIcon from '../../assets/case-studies-images/whatsapp-hero-icon.svg';
import HeroCardIcon1 from '../../assets/case-studies-images/automated-msgs.svg';
import HeroCardIcon2 from '../../assets/case-studies-images/customer-support.svg';
import HeroCardIcon3 from '../../assets/case-studies-images/integration.svg';
import IntegrationImg1 from '../../assets/case-studies-images/reach-customer.svg';
import IntegrationImg2 from '../../assets/case-studies-images/customer-experience.svg';
import IntegrationImg3 from '../../assets/case-studies-images/improve-efficiency.svg';
import IntegrationImg4 from '../../assets/case-studies-images/insight.svg';
import IntegImg1 from '../../assets/case-studies-images/develop-solution.svg';
import IntegImg2 from '../../assets/case-studies-images/connect-system.svg';
import IntegImg3 from '../../assets/case-studies-images/start-messaging.svg';
import DevelopCardIcon1 from '../../assets/case-studies-images/customized-soft1.svg';
import DevelopCardIcon2 from '../../assets/case-studies-images/customized-soft2.svg';
import DevelopCardIcon3 from '../../assets/case-studies-images/customized-soft3.svg';
import DevelopCardIcon4 from '../../assets/case-studies-images/customized-soft4.svg';
import startUpIcon1 from '../../assets/case-studies-images/benefit-startup1.svg';
import startUpIcon2 from '../../assets/case-studies-images/benefit-startup2.svg';
import startUpIcon3 from '../../assets/case-studies-images/benefit-startup3.svg';
import startUpIcon4 from '../../assets/case-studies-images/benefit-startup4.svg';
import teamImg from '../../assets/case-studies-images/tech-team-img.svg';
import teamCardIcon1 from '../../assets/case-studies-images/outsource-icon1.svg';
import teamCardIcon2 from '../../assets/case-studies-images/outsource-icon2.svg';
import teamCardIcon3 from '../../assets/case-studies-images/outsource-icon3.svg';
import teamCardIcon4 from '../../assets/case-studies-images/outsource-icon4.svg';
import teamCardIcon5 from '../../assets/case-studies-images/outsource-icon5.svg';
import teamCardIcon6 from '../../assets/case-studies-images/outsource-icon6.svg';
import teamCardIcon7 from '../../assets/case-studies-images/outsource-icon7.svg';
import teamCardIcon8 from '../../assets/case-studies-images/outsource-icon8.svg';
import featureUpIcon1 from '../../assets/case-studies-images/benefit-feature1.svg';
import featureUpIcon2 from '../../assets/case-studies-images/benefit-feature2.svg';
import featureUpIcon3 from '../../assets/case-studies-images/benefit-feature3.svg';
import featureUpIcon4 from '../../assets/case-studies-images/benefit-feature4.svg';
import featureUpIcon5 from '../../assets/case-studies-images/benefit-feature5.svg';
import {useLocation} from "react-router-dom";


const CaseStudiespage = () => {
  const location = useLocation();
  return (
    <div className='caseStudies-page'>
      <section className='tab-section'>
        <div className='case-tab-cover'>
          <div className={'case-tab-link-wrap '+(location.hash == "#WhatsappAPI" || location.hash ==''?'active':' ')}> <a href='#WhatsappAPI'>Whatsapp API for Business 1</a></div>
          <div className={'case-tab-link-wrap '+(location.hash == "#CustomizedDevelopment"?'active':' ')}><a href='#CustomizedDevelopment'>Customized Software Development</a></div>
          <div className={'case-tab-link-wrap '+(location.hash == "#TeamTechOutsourcing"?'active':' ')}><a href='#TeamTechOutsourcing'>Tech Team Outsourcing</a></div>
        </div>
      </section>
      <section className='case-hero-section' id='WhatsappAPI'>
        <div className='case-hero-wrap1 width-90'>
          <div className='case-hero-txt-wrap'>
            <h1 className='case-hero-heading'>Whatsapp API for Business</h1>
            <p className='case-hero-txt'>Think of it as a <span className='case-hero-txt-spn'>bridge between your existing business<br className='' /> tools and the world of WhatsApp messaging.</span></p>
          </div>
          <div className='case-hero-img-wrap'><img src={HeroIcon} alt='Whatsapp Direction Icon' /></div>
        </div>
        <div className='case-hero-wrap2 width-90'>
          <p className='case-hero-txt'>Unlike the individual app, <span className='case-hero-txt-spn'>this API enables:</span></p>
          <div className='case-hero-card-wrap'>
            <div className='case-hero-card'>
              <div className='case-inner-wrap'>
                <img src={HeroCardIcon1} alt='Sending automated messages' className='' />
                <p className='case-hero-card-txt'><span>Sending automated <br className='' />messages and notifications:</span> Share order updates, flight confirmations, appointment reminders, and more at scale.</p>
              </div>
            </div>
            <div className='case-hero-card'>
              <div className='case-inner-wrap'>
                <img src={HeroCardIcon2} alt='Providing customer support' className='' />
                <p className='case-hero-card-txt'><span>Providing customer support:</span> Offer real-time assistance through chatbots or live agents, directly within WhatsApp.</p>
              </div>
            </div>
            <div className='case-hero-card'>
              <div className='case-inner-wrap'>
                <img src={HeroCardIcon3} alt='Integrating with other systems' className='' />
                <p className='case-hero-card-txt'><span>Integrating with other systems:</span> Streamline communication by connecting the API to your CRM, e-commerce platform, or marketing tools.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='integration-section'>
        <div className='integration-inner-wrap width-90'>
          <h2 className='integration-heading'>Benefits of the Integration:</h2>
          <div className='integration-col-wrap'>
            <div className='integration-col'>
              <img src={IntegrationImg1} alt='Reach a wider audience' className='inte-col-img' />
              <p className='inte-col-txt'><span>Reach a wider audience:</span> <br className='' />Tap into the massive user base of WhatsApp, reaching customers where they already are.</p>
            </div>
            <div className='integration-col'>
              <img src={IntegrationImg2} alt='Enhance customer experience' className='inte-col-img' />
              <p className='inte-col-txt'><span>Enhance customer experience:</span> Offer convenient and personalized communication through a familiar platform.</p>
            </div>
            <div className='integration-col'>
              <img src={IntegrationImg3} alt='Improve efficiency' className='inte-col-img' />
              <p className='inte-col-txt'><span>Improve efficiency:</span> Automate routine tasks and manage conversations effectively, saving time and resources.</p>
            </div>
            <div className='integration-col'>
              <img src={IntegrationImg4} alt='Gain valuable insights' className='inte-col-img' />
              <p className='inte-col-txt'><span>Gain valuable insights:</span> Analyze message data to understand customer behavior and personalize future interactions.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='integ-work-section'>
        <div className='integ-inner-wrap width-90'>
          <div className='integ-heading-wrap'>
            <h3 className='integ-heading'>How Does the <br className='hide-mb' />Integration Work?</h3>
            <p className='integ-txt'>You won't be interacting with a visible app. Instead, the API operates behind the scenes, integrated with your existing software: </p>
          </div>
          <div className='integ-col-wrap'>
            <div className='integ-col'>
              <div className='integ-col-num'>1</div>
              <div className='integ-col-txt-wrap'><p className='integ-col-txt'><span className=''>Develop or choose a solution:</span> You can build your own integration or use services provided by Business Solution Providers (BSPs).</p></div>
              <div className='integ-col-img-wrap'><img src={IntegImg1} alt='Develop or choose a solution' className='integ-col-img' /></div>
            </div>
            <div className='integ-col'>
              <div className='integ-col-num'>2</div>
              <div className='integ-col-txt-wrap'><p className='integ-col-txt'><span className=''>Connect your systems:</span><br /> Integrate the API with your CRM, website, or other tools to automate workflows.</p></div>
              <div className='integ-col-img-wrap'><img src={IntegImg2} alt='Connect your systems' className='integ-col-img' /></div>
            </div>
            <div className='integ-col'>
              <div className='integ-col-num'>3</div>
              <div className='integ-col-txt-wrap'><p className='integ-col-txt'><span className=''>Start sending and receiving messages:</span> Interact with your customers directly through WhatsApp.</p></div>
              <div className='integ-col-img-wrap'><img src={IntegImg3} alt='Start sending and receiving messages' className='integ-col-img' /></div>
            </div>
          </div>
          <div className='integ-api-wrap'>
            <h3 className='integ-api-heading'>Want to add Whatsapp API <br className='' />to your software?</h3>
            <div className='integ-api-btn-wrap'>
              <a href='/services' className='start-now-btn'>Start now</a>
            </div>
          </div>
        </div>
      </section>
      <section className='develop-section' id='CustomizedDevelopment'>
        <div className='develop-col-cover'>
          <div className='develop-col-inner-wrap'>
            <div className='develop-heading-col'>
              <h4 className='develop-heading'>Customized Software <span>Development</span></h4>
              <p className='develop-txt'>Customized software development is the process of creating software specifically designed for your startup's unique needs and goals. Unlike generic software, it's tailored to your specific workflows, user base, and business model. </p>
            </div>
            <div className='develop-card-col'>
              <div className='develop-card-wrap'>
                <p className='develop-col-txt'>This allows for:</p>
                <div className='develop-card one'>
                  <img src={DevelopCardIcon1} alt='Enhanced user experience' className='' />
                  <h5 className=''>Enhanced user experience:</h5>
                  <p className=''>Design a user-friendly interface that meets your target audience's needs and expectations.</p>
                </div>
              </div>
              <div className='develop-card-wrap'>
                <div className='develop-card two'>
                  <img src={DevelopCardIcon2} alt='Scalability' className='' />
                  <h5 className=''>Scalability:</h5>
                  <p className=''>Develop software that can grow with your business, adapting to changing demands.</p>
                </div>
              </div>
              <div className='develop-card-wrap'>
                <div className='develop-card three'>
                  <img src={DevelopCardIcon3} alt='Competitive advantage' className='' />
                  <h5 className=''>Competitive advantage:</h5>
                  <p className=''>Stand out from the crowd with a unique solution that addresses your niche market's specific problems.</p>
                </div>
              </div>
              <div className='develop-card-wrap'>
                <div className='develop-card four'>
                  <img src={DevelopCardIcon4} alt='Increased efficiency' className='' />
                  <h5 className=''>Increased efficiency:</h5>
                  <p className=''>Automate tasks, streamline processes, and eliminate manual work.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='benefit-startup-cover'>
          <h4 className='startup-heading'>Benefits For Startups:</h4>
          <div className='startup-inner-wrap'>
            <div className='startup-card'>
              <div className='startup-icon-wrap'><img src={startUpIcon1} alt='Solve specific problems' className='startup-card-icon'/></div>
              <h5 className='startup-card-heading'>Solve specific problems:</h5>
              <p className='startup-card-txt'>Address challenges that generic software can't handle, giving you a unique selling proposition.</p>
            </div>
            <div className='startup-card'>
              <div className='startup-icon-wrap'><img src={startUpIcon2} alt='Validate your idea' className='startup-card-icon'/></div>
              <h5 className='startup-card-heading'>Validate your idea:</h5>
              <p className='startup-card-txt'>Develop a Minimum Viable Product (MVP) to test your concept and gather user feedback before investing heavily.</p>
            </div>
            <div className='startup-card'>
              <div className='startup-icon-wrap'><img src={startUpIcon3} alt='Attract investors' className='startup-card-icon'/></div>
              <h5 className='startup-card-heading'>Attract investors:</h5>
              <p className='startup-card-txt'>Showcase your commitment to innovation and long-term vision with a bespoke software solution.</p>
            </div>
            <div className='startup-card'>
              <div className='startup-icon-wrap'><img src={startUpIcon4} alt='Build brand loyalty' className='startup-card-icon'/></div>
              <h5 className='startup-card-heading'>Build brand loyalty:</h5>
              <p className='startup-card-txt'>Offer an exceptional user experience that sets you apart from competitors.</p>
            </div>
          </div>
        </div>
        <div className='go-cover width-90'>
          <div className='go-wrap'>
            <h3 className='go-heading'>Ready to Go Custom?</h3>
            <p className='go-txt'>If you're looking to build a software solution that truly reflects your startup's <br className='' />vision and propels you forward, customized development is worth exploring.</p>
            <div className='go-btn-wrap'>
              <a href='/services' className='start-now-btn'>Start now</a>
            </div>
          </div>
        </div>
      </section>
      <section className='team-section' id='TeamTechOutsourcing'>
        <div className='team-inner-wrap width-90'>
          <div className='team-cover'>
            <div className='team-txt-wrap'>
              <h4 className='team-heading'>Tech Team Outsourcing</h4>
              <p className='team-txt1'>Streamline Your Product Development: Unleash Your Vision with our Dedicated Resources</p>
              <p className='team-txt2'>Developing a cutting-edge product requires expertise, dedication, and efficiency. Our comprehensive product development offering equips you with the essential tools and resources to <span>transform your vision into reality:</span></p>
            </div>
            <div className='team-img-wrap'><img src={teamImg} alt='Tech Team Outsourcing' className='team-img' /></div>
          </div>
          <div className='team-card-cover'>
            <div className='team-card-wrap'>
              <div className='team-card rct'>
                <div className='team-card-img-wrap'><img src={teamCardIcon1} alt='Free Cost Estimation' className=''/></div>
                <div className='team-card-txt-wrap'>
                  <h6>Free Cost Estimation:</h6>
                  <p>Start confidently with a transparent understanding of project costs and timelines.</p>
                </div>
              </div>
              <div className='team-card rct'>
                <div className='team-card-img-wrap'><img src={teamCardIcon2} alt='Prototype and Wireframing' className=''/></div>
                <div className='team-card-txt-wrap'>
                  <h6>Prototype and Wireframing:</h6>
                  <p>Explore and refine your product concept with interactive prototypes and user flows.</p>
                </div>
              </div>
              <div className='team-card rct'>
                <div className='team-card-img-wrap'><img src={teamCardIcon3} alt='Timely Delivery' className=''/></div>
                <div className='team-card-txt-wrap'>
                  <h6>Timely Delivery:</h6>
                  <p>Meet your launch deadlines with agile development practices and transparent communication.</p>
                </div>
              </div>
            </div>
            <div className='team-card-wrap'>
              <div className='team-card sqr'>
                <div className='team-card-img-wrap'><img src={teamCardIcon4} alt='Expert UI/UX Design' className='' /></div>
                <div className='team-card-txt-wrap'>
                  <h6>Expert UI/UX Design:</h6>
                  <p>Captivate your audience with intuitive and visually stunning interfaces tailored to user needs.</p>
                </div>
              </div>
              <div className='team-card sqr'>
                <div className='team-card-img-wrap'><img src={teamCardIcon5} alt='Free Dedicated Quality Assurance' className='' /></div>
                <div className='team-card-txt-wrap'>
                  <h6>Free Dedicated Quality Assurance:</h6>
                  <p>Ensure flawless functionality and a polished user experience with comprehensive testing.</p>
                </div>
              </div>
            </div>
            <div className='team-card-wrap'>
              <div className='team-card rct'>
                <div className='team-card-img-wrap'><img src={teamCardIcon6} alt='Premium Code Quality' className=''/></div>
                <div className='team-card-txt-wrap'>
                  <h6>Premium Code Quality:</h6>
                  <p>Experience peace of mind with robust, secure, and well-documented code.</p>
                </div>
              </div>
              <div className='team-card rct'>
                <div className='team-card-img-wrap'><img src={teamCardIcon7} alt='Free Dedicated Project Management' className=''/></div>
                <div className='team-card-txt-wrap'>
                  <h6>Free Dedicated Project Management:</h6>
                  <p>Stay informed and in control with a dedicated project manager who oversees every step.</p>
                </div>
              </div>
              <div className='team-card rct'>
                <div className='team-card-img-wrap'><img src={teamCardIcon8} alt='Weekly Sprints' className=''/></div>
                <div className='team-card-txt-wrap'>
                  <h6>Weekly Sprints:</h6>
                  <p>Track progress in real-time with clear milestones and regular communication through weekly sprints.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='feature-section'>
        <div className='feature-inner-wrap'>
          <div className='benefit-feature-cover'>
            <h4 className='feature-heading'>Benefits Beyond Features:</h4>
            <div className='feature-inner-wrap'>
              <div className='feature-card'>
                <div className='feature-icon-wrap'><img src={featureUpIcon1} alt='Reduced Risks' className='feature-card-icon'/></div>
                <h5 className='feature-card-heading'>Reduced Risks:</h5>
                <p className='feature-card-txt'>Mitigate development complexities and potential delays with proven processes and experienced professionals.</p>
              </div>
              <div className='feature-card'>
                <div className='feature-icon-wrap'><img src={featureUpIcon2} alt='Increased Efficiency' className='feature-card-icon'/></div>
                <h5 className='feature-card-heading'>Increased Efficiency:</h5>
                <p className='feature-card-txt'>Focus on your core business strengths while our team handles the technical intricacies.</p>
              </div>
              <div className='feature-card'>
                <div className='feature-icon-wrap'><img src={featureUpIcon3} alt='Enhanced Collaboration' className='feature-card-icon'/></div>
                <h5 className='feature-card-heading'>Enhanced Collaboration:</h5>
                <p className='feature-card-txt'>Enjoy open communication and a transparent partnership throughout the development journey.</p>
              </div>
              <div className='feature-card'>
                <div className='feature-icon-wrap'><img src={featureUpIcon4} alt='Cost-Effectiveness' className='feature-card-icon'/></div>
                <h5 className='feature-card-heading'>Cost-Effectiveness:</h5>
                <p className='feature-card-txt'>Access premium resources and expertise without the overhead of building an in-house team.</p>
              </div>
              <div className='feature-card'>
                <div className='feature-icon-wrap'><img src={featureUpIcon5} alt='Faster Time to Market' className='feature-card-icon'/></div>
                <h5 className='feature-card-heading'>Faster Time to Market:</h5>
                <p className='feature-card-txt'>Accelerate your product launch with efficient development cycles and clear deliverables.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='potential-cover width-90'>
          <div className='potential-wrap'>
            <h3 className='potential-heading'>Unlock the full potential of <br className='hide-mb' />your product idea. </h3>
            <p className='potential-txt'>Contact us today for a free consultation and experience the <br className='hide-mb' />difference dedicated resources can make.</p>
            <div className='potential-btn-wrap'>
              <a href='/services' className='start-now-btn'>Start now</a>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default CaseStudiespage