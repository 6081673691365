import React from 'react';
import UndrCardImg1 from '../../assets/aboutpage-images/understand-card-img1.svg';
import UndrCardImg2 from '../../assets/aboutpage-images/understand-card-img2.svg';
import UndrCardImg3 from '../../assets/aboutpage-images/understand-card-img3.svg';
import ChooseCardIcon1 from '../../assets/aboutpage-images/experties.svg';
import ChooseCardIcon2 from '../../assets/aboutpage-images/entrepreneurial.svg';
import ChooseCardIcon3 from '../../assets/aboutpage-images/global.svg';
import ChooseCardIcon4 from '../../assets/aboutpage-images/DirectionIconBlack.svg';
import ChooseCardIcon5 from '../../assets/aboutpage-images/Approach.svg';
import officeMap from '../../assets/homepage-images/offices-map.svg';
import officeListImg1 from '../../assets/homepage-images/list-logo1.svg';
import officeListImg2 from '../../assets/homepage-images/list-logo2.svg';
import officeListImg3 from '../../assets/homepage-images/list-logo3.svg';
import officeListImg4 from '../../assets/homepage-images/list-logo4.svg';


const AboutpageAr = () => {
  return (
    <div className='aboutpage arabic'>
            <section className='about-hero-section'>
                <div className='abt-hero-wrap'>
                    <h1 className='abt-hero-heading'>تمكين الشركات الناشئة <br className='hide-mb' />بالخبرة والشغف</h1>
                    <p className='abt-hero-txt'>تسعى <span className='abt-hero-txt-spn'>دايركشن نورث</span> إلى أن تكون <span className='abt-hero-txt-spn'>شريكك الموثوق به</span> في رحلة تطورك في الساحة التكنولوجيا . باعتبارنا شركة تطوير تتمتع <span className='abt-hero-txt-spn'>بخبرة تزيد عن 11 عامًا</span>، فإننا متخصصون في <span className='abt-hero-txt-spn'>مساعدة الشركات الناشئة</span> و متوسطة مثل شركتك على الاستعانة <span className='abt-hero-txt-spn'>بمواهب تقنية</span> وبناء منتجات مبتكرة تؤدي إلى نمو وتحقيق أهدافك.</p>
                </div>
            </section>
            <section className='understand-section'>
                <div className='understand-wrap'>
                    <div className='understand-card heading'><h2 className='understart-heading'> نحن نفهم التحديات التي تواجهك</h2></div>
                    <div className='understand-card bg-white'>
                        <div className='undr-card-txt-wrap'>
                            <h3 className='undr-card-heading'> هل لديك خبرة مواهب تقنية محدودة؟</h3>
                            <p className='undr-card-txt'> لا تقلق! نحن نقدم حلولاً شاملة، بدءًا من تطوير الويب/التطبيقات وحتى البرامج المخصصة والتكامل السلس مع منتجات Meta.</p>
                        </div>
                        <div className='undr-card-img-wrap'><img src={UndrCardImg1} alt='Tech Expertise' className='undr-card-img' /></div>
                    </div>
                    <div className='understand-card bg-white'>
                        <div className='undr-card-txt-wrap'>
                            <h3 className='undr-card-heading'>هل تشعر بالرهبة من عالم الشركات الناشئة فائق السرعة؟</h3>
                            <p className='undr-card-txt'>نحن من هذا العالم و نعرف ما تشعر به! فريقنا من عالم ريادة الأعمال وسيقدم لك الدعم والإرشادات التي لا تقدر بثمن.</p>
                        </div>
                        <div className='undr-card-img-wrap'><img src={UndrCardImg2} alt='Scalability' className='undr-card-img' /></div>
                    </div>
                    <div className='understand-card bg-white'>
                        <div className='undr-card-txt-wrap'>
                            <h3 className='undr-card-heading'>هل تشعر أن زيادة  حجم الفريق يسبب عبء و ضغط على ميزانيتك المستقبلية؟</h3>
                            <p className='undr-card-txt'>فقدان البوصلة هو جزء من كل رحلة. فريقنا لاستشارات ريادة الأعمال سيتفاعل مع جميع مخاوفك بإيجابية ويوفر لك إرشادات لا تقدر بثمن.</p>
                        </div>
                        <div className='undr-card-img-wrap'><img src={UndrCardImg3} alt='Startup' className='undr-card-img' /></div>
                    </div>
                </div>
            </section>
            <section className='choose-section'>
                <div className='choose-wrapper'>
                    <div className='choose-col heading'>
                        <div className='choose-card'><h4 className='choose-heading'>لماذا تختار دايركشن نورث؟</h4></div>
                    </div>
                    <div className='choose-col'>
                        <div className='choose-card green'>
                            <div className='choose-card-data-wrap'>
                                <div className='choose-card-head-wrap'>
                                        <img src={ChooseCardIcon1} alt='Deep Expertise' className='choose-card-icon' />
                                        <h5 className='choose-card-heading'>الخبرة الكبيرة</h5>
                                    </div>
                                <p className='choose-card-txt'>يتمتع فريقنا من المطورين بخبرة و مشاريع في مختلف التقنيات، مما يطمئنك من الموارد العاملة على نجاح مشروعك.</p>
                            </div>
                        </div>
                    </div>
                    <div className='choose-col'>
                        <div className='choose-card orange'>
                            <div className='choose-card-data-wrap'>
                                <div className='choose-card-head-wrap'>
                                        <img src={ChooseCardIcon2} alt='Entrepreneurial Spirit' className='choose-card-icon' />
                                        <h5 className='choose-card-heading'>روح المبادرة</h5>
                                    </div>
                                <p className='choose-card-txt'>نحن نفهم رحلتك الناشئة ونتحدث لغتك. نحن لسنا مجرد مطورين، بل نحن شركاء نمو شركتك.</p>
                            </div>
                        </div>
                    </div>
                    <div className='choose-col'>
                        <div className='choose-card blue'>
                            <div className='choose-card-data-wrap'>
                                <div className='choose-card-head-wrap'>
                                        <img src={ChooseCardIcon3} alt='Global Reach' className='choose-card-icon' />
                                        <h5 className='choose-card-heading'>الوجود الإقليمي</h5>
                                    </div>
                                <p className='choose-card-txt'>من خلال مكاتبنا في القاهرة والرياض وإسلام أباد، نقدم قابلية التوسع و توفير مواهب متنوعة لتلبية احتياجاتك.</p>
                            </div>
                        </div>
                    </div>
                    <div className='choose-col'>
                        <div className='choose-card grey'>
                            <div className='choose-card-inner'>
                                <img src={ChooseCardIcon4} alt='Direction Icon' className='choose-direction-icon' />
                            </div>
                        </div>
                    </div>
                    <div className='choose-col'>
                        <div className='choose-card yellow'>
                            <div className='choose-card-data-wrap'>
                                <div className='choose-card-head-wrap'>
                                        <img src={ChooseCardIcon5} alt='Customer-Centric Approach' className='choose-card-icon' />
                                        <h5 className='choose-card-heading'>رضا عملائنا من أهم اهدافنا</h5>
                                    </div>
                                <p className='choose-card-txt'> نحن نتعاون معك بشكل وثيق، ونشاركك بشفافية كاملة طوال عملية التطوير</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='about office-section'>
                <div className='about office-wrapper'>
                    <div className='office-txt-wrap'>
                        <h4 className='office-heading'>مكاتبنا</h4>
                        <p className='office-txt'> قريبة منك </p>
                        <ul className='offices-list'>
                            <li><span className='offices-li-spn'><img src={officeListImg1} alt='Cairo' /></span>القاهرة</li>
                            <li><span className='offices-li-spn'><img src={officeListImg2} alt='Riyadh' /></span>الرياض</li>
                            <li><span className='offices-li-spn'><img src={officeListImg3} alt='Islamabad' /></span>إسلام آباد</li>
                            <li><span className='offices-li-spn'><img src={officeListImg4} alt='United states' /></span>الولايات المتحدة الأمريكية</li>
                        </ul>
                    </div>
                    <div className='office-img-wrap'><img src={officeMap} alt='' className='office-map-img' /></div>
                </div>
            </section>
            <section className='journey-section'>
                <div className='journey-wrap'>
                    <div className='journey-txt-wrap'>
                        <h4 className='journey-heading'>هل أنت مستعد <span className='jpurney-head-spn'>للبدء في<br className='hide-mb' /> رحلتك التقنية بثقة؟</span></h4>
                        <p className='journey-txt'>اتصل بنا اليوم ودعنا نناقش كيف يمكن أن تساعدك<br className='hide-mb' /> دايركشن نورث في تحقيق أهدافك الطموحة.</p>
                    </div>
                    <div className='journey-btn-wrap'>
                        <a href='/ar/services' className='start-now-btn'>ابدأ الآن</a>
                    </div>
                </div>
            </section>
        </div>
  )
}

export default AboutpageAr