import React from 'react';
import APIMasonryAr from './APIMasonryAr';
import ClinicMasonryAr from './ClinicMasonryAr';
import ConcreteMasonryAr from './ConcreteMasonryAr';
import EgentMasonryAr from './EgentMasonryAr';
import FlashleadMasonryAr from './FlashleadMasonryAr';
import TopTechMasonryAr from './TopTechMasonryAr';

const PortfoliopageAr = () => {
  return (
    <div className='portfolio-page arabic'>
        <FlashleadMasonryAr />
        <ClinicMasonryAr />
        <ConcreteMasonryAr />
        <EgentMasonryAr />
        <TopTechMasonryAr />
        <APIMasonryAr />
    </div>
  )
}

export default PortfoliopageAr