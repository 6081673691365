import React from 'react';
import Aboutpage from '../components/Aboutpage/Aboutpage.js'
import AboutpageAr from '../components/Aboutpage/AboutpageAr.jsx';

const About = () => {
    return (
        <>
            <AboutpageAr />
            {/* <Aboutpage /> */}
        </>
    )
}

export default About