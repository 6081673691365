import React from 'react';
import './TopTechMasonry.css';
import ToptechLogo from '../../assets/portfolio-images/ToptechLogo.svg';
import ServicesOrngImg from '../../assets/homepage-images/dedicated-team.svg';
import DurationBlueIcon from '../../assets/portfolio-images/ClockBlue.svg';
import EgyptFlag from '../../assets/portfolio-images/EgyptFlag.svg';
import ToptechWebImg from '../../assets/portfolio-images/toptech-web.svg';

const TopTechMasonryAr = () => {
  return (
    <>
        <section className='toptech-section'>
            <div className='toptech-wrap width-90'>
                <h1 className='toptech-heading'>Top Tech</h1>
                <p className='toptech-txt'>نظام مخصص لتجميع العملاء المحتملين للحملات التسويقية.</p>
                <div className='toptech-card-covers'>
                    <div className='toptech-cardCol'>
                        <div className='top-logo-web-wrap'>
                            <div className='toptech-card-logo'><img src={ToptechLogo} alt='Top Tech' className='toptech-logo-img' /></div>
                            <div className='toptech-card-web-design hide-mb'>
                                <h4>موقع إلكتروني</h4>
                                <img src={ToptechWebImg} alt='TopTech Website' />
                            </div>
                        </div>
                        <div className='top-dur-des-wrap'>
                            <div className='toptech-card-description'>
                                <h3>Toptech</h3>
                                <p>لقد صممنا صفحة نزول تتمتع بأداء ممتاز في دفع الزوار لتسجيل بياناتهم وتحويلهم إلى عملاء محتملين بالاضافة إلى ربط هذا بمنصة مصممة لحفظ وتحسين النتائج، واستخدمت شركة Toptech، فريقنا لإنشاء الصفحة على أحدث تصميم والتي كانت سبب في فوزها بـجائزة "حملة العام" من "Dell EMC"</p>
                            </div>
                            <div className='toptech-card-duration hide-mb'>
                                <img src={DurationBlueIcon} alt='Duration' className='toptech-duration-icon' />
                                <h4>المدة</h4>
                                <p>1 شهر</p>
                            </div>
                        </div>
                    </div>
                    <div className='duration-outer-wrap hide-dk'>
                        <div className='toptech-card-duration'>
                            <img src={DurationBlueIcon} alt='Duration' className='toptech-duration-icon' />
                            <h4>المدة</h4>
                            <p>1 شهر</p>
                        </div>
                        <div className='toptech-card-country'>
                            <h4>الدولة</h4>
                            <p>مصر</p>
                            <img src={EgyptFlag} alt='Egypt' className='toptech-flag-icon' />
                        </div>
                    </div>
                    <div className='toptech-cardCol hide-mb'>
                        <div className='toptech-card-service'>
                            <img src={ServicesOrngImg} alt='Dedicate Team' className='toptech-services-img' />
                            <h4>الخدمة</h4>
                            <p>توفير الفريق التقني</p>
                        </div>
                        <div className='toptech-card-country'>
                            <h4>الدولة</h4>
                            <p>مصر</p>
                            <img src={EgyptFlag} alt='Egypt' className='toptech-flag-icon' />
                        </div>
                    </div>
                </div>
                <p className='toptech-website-wrap'>Visit Website: <a href='https://www.toptechegypt.com/' target='_blank'>www.toptechegypt.com</a></p>
            </div>
        </section>
    </>
  )
}

export default TopTechMasonryAr