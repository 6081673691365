import React from 'react';
import './EgentMasonry.css';
import EgentLogo from '../../assets/portfolio-images/EgentLogo.svg';
import ServicesBlueImg from '../../assets/homepage-images/product-solutions.svg';
import DurationBlueIcon from '../../assets/portfolio-images/ClockBlue.svg';
import EgyptFlag from '../../assets/portfolio-images/EgyptFlag.svg';
import EgentWebImg1 from '../../assets/portfolio-images/egent-web-img1.svg';
import EgentWebImg2 from '../../assets/portfolio-images/egent-web-img2.svg';


const EgentMasonryAr = () => {
  return (
    <>
        <section className='egent-section'>
            <div className='egent-wrap width-90'>
                <h1 className='egent-heading'>المركز المصري الألماني للأنف، الأذن و الحنجرة:</h1>
                <p className='egent-txt'>موقع مصمم ليتميز في محركات البحث (SEO) لأحد المجمعات الطبية الرائدة في طب الأذن والأنف والحنجرة.</p>
                <div className='egent-card-covers'>
                    <div className='egent-cardCol'>
                        <div className='egent-card-logo'><img src={EgentLogo} alt='Egent' className='egent-logo-img' /></div>
                        <div className='egent-card-web-design webcard1 hide-mb'>
                            <h4>موقع إلكتروني</h4>
                            <img src={EgentWebImg1} alt='Egent Website' />
                        </div>
                    </div>
                    <div className='duration-outer-wrap hide-dk'>
                        <div className='egent-card-duration'>
                            <img src={DurationBlueIcon} alt='Duration' className='egent-duration-icon' />
                            <h4>المدة</h4>
                            <p>3 أشهر</p>
                        </div>
                        <div className='egent-card-country'>
                            <h4>الدولة</h4>
                            <p>مصر</p>
                            <img src={EgyptFlag} alt='Egypt' className='egent-flag-icon' />
                        </div>
                    </div>
                    <div className='egent-cardCol'>
                        <div className='egent-card-description'>
                            <h3>إيجنت</h3>
                            <p> لقد قمنا <span className='fw-5'>بتصميم موقع إلكتروني يتميز في محركات البحث الرئيسية</span> والذي كان بمثابة أداة تسويقية قوية، ووضع مركز Egent الطبي في مقدمة الظهور في نتائج البحث في وقت قياسي، وفي هذا المشروع طورت دايركشن نورث فهمًا عميقًا للصناعة الطبية وتحدياتها؛ عملت على سهولة التنقل بين الصفحات والمحتوى الجذاب و الفعال باللغة العربية و الإنجليزية وتحسين أداء الأجهزة المحمولة.</p>
                        </div>
                        <div className='duration-outer-wrap hide-mb'>
                            <div className='egent-card-web-design webcard2'>
                                <img src={EgentWebImg2} alt='Egent Website' />
                            </div>
                            <div className='egent-card-duration'>
                                <img src={DurationBlueIcon} alt='Duration' className='egent-duration-icon' />
                                <h4>المدة</h4>
                                <p>3 أشهر</p>
                            </div>
                        </div>
                    </div>
                    <div className='egent-cardCol hide-mb'>
                        <div className='egent-card-service'>
                            <h4>المدةالخدمة</h4>
                            <p>تطوير المنتجات</p>
                            <img src={ServicesBlueImg} alt='Product Development' className='egent-services-img' />
                        </div>
                        <div className='egent-card-country'>
                            <h4>الدولة</h4>
                            <p>مصر</p>
                            <img src={EgyptFlag} alt='Egypt' className='egent-flag-icon' />
                        </div>
                    </div>
                </div>
                <p className='egent-website-wrap'>Visit Website: <a href='https://www.egentcenters.com/' target='_blank'>www.egentcenters.com</a></p>
            </div>
        </section>
    </>
  )
}

export default EgentMasonryAr