import React from 'react';
import './APIMasonry.css';
import APILogo from '../../assets/portfolio-images/APILogo.svg';
import ServicesOrngImg from '../../assets/homepage-images/dedicated-team.svg';
import DurationBlueIcon from '../../assets/portfolio-images/ClockBlue.svg';
import UAEFlag from '../../assets/portfolio-images/UAEFlag.svg';



const APIMasonry = () => {
  return (
    <>
        <section className='api-section'>
            <div className='api-wrap width-90'>
                <h1 className='api-heading'>Custom Whatsapp API integration (Confidential)</h1>
                <p className='api-txt'>Superior SEO friendly website for one of the leading medical complex of Ear, nose and throat.</p>
                <div className='api-card-covers'>
                    <div className='api-cardCol'>
                        <div className='api-card-logo'><img src={APILogo} alt='Whatsapp API' className='api-logo-img' /></div>
                        <div className='api-card-duration hide-mb'>
                            <img src={DurationBlueIcon} alt='Duration' className='api-duration-icon' />
                            <h4>Duration</h4>
                            <p>2 months</p>
                        </div>
                    </div>
                    <div className='api-cardCol'>
                        <div className='api-card-description'>
                            <h3>Custom Whatsapp <br />API integration</h3>
                            <p>Leveraging our powerful partnership and deep meta expertise, we unlocked seamless messaging for a leading tech startup in this project we achieved effortless client support: Through a custom WhatsApp Business API integration, Targeted marketing through enabling Personalized, engaging new-offer announcements reached clients directly, driving excitement and conversions at unprecedented rates and we achieved complete compliance with the startup's privacy requirements, fostering client loyalty and long-term success.</p>
                        </div>
                    </div>
                    <div className='duration-outer-wrap hide-dk'>
                        <div className='api-card-duration'>
                            <img src={DurationBlueIcon} alt='Duration' className='api-duration-icon' />
                            <h4>Duration</h4>
                            <p>2 months</p>
                        </div>
                        <div className='api-card-country'>
                            <img src={UAEFlag} alt='UAE' className='api-flag-icon' />
                            <h4>Country</h4>
                            <p>UAE</p>
                        </div>
                    </div>
                    <div className='api-cardCol hide-mb'>
                        <div className='api-card-service'>
                            <h4>Services</h4>
                            <p>Dedicate <br />Team</p>
                            <img src={ServicesOrngImg} alt='Dedicate Team' className='api-services-img' />
                        </div>
                        <div className='api-card-country'>
                            <img src={UAEFlag} alt='UAE' className='api-flag-icon' />
                            <h4>Country</h4>
                            <p>UAE</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default APIMasonry