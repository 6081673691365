import React from 'react';
import ServicesImage1 from '../../assets/homepage-images/product-solutions.svg';
import ServicesImage2 from '../../assets/homepage-images/dedicated-team.svg';
import ServicesLiBlue from '../../assets/services-images/direction-blue-icon-small.svg'
import ServicesLiOrange from '../../assets/services-images/direction-orange-icon-small.svg'
import Multiselect from 'multiselect-react-dropdown';
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const ServicespageAr = () => {
    const recaptchaRef = React.createRef();
    const [disPopup, setPopup] = React.useState(false);
    const [selectedType,setType] = React.useState('');
    const [pdDevType,setpddevType] = React.useState('');
    const [pdPlatform,setpdPlatForm] = React.useState('');
    const [pdtechstack,setpdtechstack] = React.useState('');
    const [dtdev,setdtdev] = React.useState('');
    const [dtduration,setdtdur] = React.useState('');
    const [dtPlatform,setdtPlatForm] = React.useState('');
    const [phone,setPhone] = React.useState('+966');


    const handleSubmission = (e) => {
        e.preventDefault();
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(document.getElementById('email').value)) {
            alert('please select correct email address');
            return;
        }

        if(document.getElementById('name').value == "" || phone == "" || selectedType == ""){
            alert('Please fill all information');
            return;
        }

        if(recaptchaRef.current.getValue() == ''){
            alert('Captcha not selected');
            return;
        }

        const data = {
            name: document.getElementById('name').value,
            email: document.getElementById('email').value,
            phone: phone,
            captcha: recaptchaRef.current.getValue(),
            type: selectedType,
            pdDevType: pdDevType,
            pdPlatform: pdPlatform,
            pdtechstack: pdtechstack,
            dtdev: dtdev,
            dtduration: dtduration,
            dtPlatform: dtPlatform
        };

        // Send a POST request to the API endpoint
        try {
            const response =  fetch('https://direction-north.com/submit-request.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then((response) => response.json())
                .then((data) => {
                   alert(data.message);
                    // setPopup(false);
                    window.location.href = "/ar/thankyou";
                })
                .catch((error) => console.error(error));
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handlePDCLick = (e) => {
        e.preventDefault();
        if(pdDevType == '' || pdtechstack == '' || pdPlatform == ''){
            alert('Please select the options in project development section before proceeding further');
            return false;
        }
        setPopup(true);
        setType('PD');
    }

    const handleDTCLick = (e) => {
        e.preventDefault();

        if(dtdev == '' || dtduration == '' || dtPlatform == ''){
            alert('Please select the options in dedicated team section before proceeding further');
            return false;
        }

        setPopup(true);
        setType('DT');
    }

    const handleClose = (e) => {
        e.preventDefault();
        setPopup(false);
        setType('');
    }

    const handlepdPlatFormChange = (e) => {
        setpdPlatForm(e.target.value);
    };

    const handleDTDevChange = (e) => {
        setdtdev(e.target.value);
    };

    const handleDTDurChange = (e) => {
        setdtdur(e.target.value);
    };

    const handleDTplatformChange = (value) => {
        setdtPlatForm(value);
    };

    const handleDevTypeChange = (e) => {
        setpddevType(e.target.value);
    };

    const handlepdStackChange = (value) => {
        setpdtechstack(value);
    };
  return (
    <div className='servicepage arabic'>
            <section className='services-section'>
                <div className='services-wrapper'>
                    <h1 className='services-heading'>Choose your desired service</h1>
                    <div className='services-inner-wrapper'>
                        <div className='services-card'>
                            <img src={ServicesImage1} alt='Product Developmet' className='services-img' />
                            <h3 className='services-card-heading'>تطوير منتج رقمي</h3>
                            <ul className='services-ul blue'>
                                <li className='services-li'><span className='services-li-spn'><img src={ServicesLiBlue} alt='Direction Blue Icon' className='services-li-img' /></span>تقدير التكلفة مجانا</li>
                                <li className='services-li'><span className='services-li-spn'><img src={ServicesLiBlue} alt='Direction Blue Icon' className='services-li-img' /></span>تصميم احترافي لواجهة /تجربة المستخدم</li>
                                <li className='services-li'><span className='services-li-spn'><img src={ServicesLiBlue} alt='Direction Blue Icon' className='services-li-img' /></span>النموذج الأولي وإطار التصميم</li>
                                <div className='services-li-wrap'>
                                <li className='services-li'><span className='services-li-spn'><img src={ServicesLiBlue} alt='Direction Blue Icon' className='services-li-img' /></span>جودة عالية في البرمجة</li>
                                <li className='services-li'><span className='services-li-spn'><img src={ServicesLiBlue} alt='Direction Blue Icon' className='services-li-img' /></span>متخصص ضمان الجودة </li>
                                </div>
                                <div className='services-li-wrap'>
                                <li className='services-li'><span className='services-li-spn'><img src={ServicesLiBlue} alt='Direction Blue Icon' className='services-li-img' /></span>مدير للمشروع إحترافي</li>
                                <li className='services-li'><span className='services-li-spn'><img src={ServicesLiBlue} alt='Direction Blue Icon' className='services-li-img' /></span>التسليم في الوقت المحدد </li>
                                </div>
                                <li className='services-li mt-0'><span className='services-li-spn'><img src={ServicesLiBlue} alt='Direction Blue Icon' className='services-li-img' /></span>الخطة الأسبوعية SPRINT :</li>
                                <li className='services-li'>
                                    <ul className='services-sub-ul'>
                                        <li className='services-sub-li'> ما الذي تسعى لتطويره</li>
                                        <div className='serv-sub-li-wrap'>
                                            <li className='services-sub-li'>المنصة التي تحتاج إلى العنصر</li>
                                            <li className='services-sub-li m-0'>لغة البرمجيات المفضلة</li>
                                        </div>
                                    </ul>
                                </li>
                            </ul>
                            <div className='services-form-wrapper'>
                                <form>
                                    <div className='serv-form-field-wrap'>
                                        {/* <label for='selectDevelop' className='serv-form-lbl'>What are you looking to develop ?</label> */}
                                        <select id='selectDevelop' className='serv-develop-field' onChange={handleDevTypeChange}>
                                            <option value="">ما الذي تسعى لتطويره؟</option>
                                            <option value="MVP">MVP</option>
                                            <option value="Complete Product Development">Complete Product Development</option>
                                            <option value="UX/UI Design">UX/UI Design</option>
                                            <option value="Working Prototype">Working Prototype</option>
                                            <option value="Something else">Something else</option>
                                        </select>
                                    </div>
                                    <div className='serv-form-field-wrap'>
                                        {/* <label for='selectPlatform' className='serv-form-lbl'>For which platform ?</label> */}
                                        <select id='selectPlatform' className='serv-platform-field' onChange={handlepdPlatFormChange}>
                                            <option value="">ما هي المنصة؟</option>
                                            <option value="Web">Web</option>
                                            <option value="Mobile">Mobile</option>
                                            <option value="Desktop">Desktop</option>
                                            <option value="All of them">All of them</option>
                                        </select>
                                    </div>
                                    <div className='serv-form-field-wrap'>
                                        {/* <label for='ServicesStartTech' className='serv-form-lbl'>Preferred Tech Stack ?</label> */}

                                        <Multiselect
                                            isObject={false}
                                            id="ServicesStartTech"
                                            onKeyPressFn={function noRefCheck(e){}}
                                            onRemove={function noRefCheck(value){handlepdStackChange(value);}}
                                            onSearch={function noRefCheck(e){}}
                                            onSelect={function noRefCheck(value){handlepdStackChange(value);}}
                                            options={[
                                                'Backend',
                                                'Frontend',
                                                'UI/UX',
                                                'Javascript',
                                                'Python',
                                                'React JS',
                                                'Node JS',
                                                'Elixir',
                                                'Flutter',
                                                'Golang',
                                                'RoR',
                                                'Django',
                                                'MERN Stack',
                                                'MEAN Stack'
                                            ]}
                                        />
                                    </div>
                                    <div className='serv-btn-wrap'>
                                        <a href='#' className='form-submit-btn' onClick={handlePDCLick}>احصل على عرض سعر</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='services-card'>
                            <img src={ServicesImage2} alt='Dedicated Team' className='services-img' />
                            <h3 className='services-card-heading'>فريق تقني متخصص</h3>
                            <ul className='services-ul orange'>
                                <li className='services-li'><span className='services-li-spn'><img src={ServicesLiOrange} alt='Direction Orange Icon' className='services-li-img' /></span>فريق تقني متخصص</li>
                                <li className='services-li'><span className='services-li-spn'><img src={ServicesLiOrange} alt='Direction Orange Icon' className='services-li-img' /></span>نظام تسعير يمتاز بالشفافية</li>
                                <li className='services-li'><span className='services-li-spn'><img src={ServicesLiOrange} alt='Direction Orange Icon' className='services-li-img' /></span>مجموعة واسعة من المواهب التقنية من مجالات متعددة</li>
                                <li className='services-li'><span className='services-li-spn'><img src={ServicesLiOrange} alt='Direction Orange Icon' className='services-li-img' /></span>يمكنك طلب مدير للمشروع</li>
                                <li className='services-li'><span className='services-li-spn'><img src={ServicesLiOrange} alt='Direction Orange Icon' className='services-li-img' /></span>مرونة في حجم الفريق وقابلية تكبيره أو تصغيره</li>
                                <li className='services-li'><span className='services-li-spn'><img src={ServicesLiOrange} alt='Direction Orange Icon' className='services-li-img' /></span>التواصل المباشر مع المطورين</li>
                                <li className='services-li'>
                                    <ul className='services-sub-ul'>
                                        <li className='services-sub-li'>حدد عدد المطورين</li>
                                        <div className='serv-sub-li-wrap'>
                                            <li className='services-sub-li'>ضع فترة مبدأية للمشروع</li>
                                            <li className='services-sub-li m-0'>اختر االاحتياجات التقنية</li>
                                        </div>
                                    </ul>
                                </li>
                            </ul>
                            <div className='services-form-wrapper'>
                                <form>
                                    <div className='serv-form-field-wrap'>
                                        {/* <label for='selectDeveloperNeed' className='serv-form-lbl'>How many developers do you need ?</label> */}
                                        <select id='selectDeveloperNeed' className='serv-develop-field' onChange={handleDTDevChange}>
                                            <option value="">كم عدد المطورين الذين تحتاجهم؟</option>
                                            <option>2-6 Developers</option>
                                            <option>6-10 Developers</option>
                                            <option>10+ Developers</option>
                                        </select>
                                    </div>
                                    <div className='serv-form-field-wrap'>
                                        {/* <label for='selectDuration' className='serv-form-lbl'>For How Long ?</label> */}
                                        <select id='selectDuration' className='serv-platform-field' onChange={handleDTDurChange}>
                                            <option value="">المدة التي تحتاجها؟</option>
                                            <option>1-3 Months</option>
                                            <option>3-6 Months</option>
                                            <option>6-10 Months</option>
                                            <option>10+ Months</option>
                                        </select>
                                    </div>
                                    <div className='serv-form-field-wrap'>
                                        {/* <label for='TeamStartTech' className='serv-form-lbl'>Preferred Tech Stack ?</label> */}
                                        <Multiselect
                                            isObject={false}
                                            id="TeamStartTech"
                                            onKeyPressFn={function noRefCheck(){}}
                                            onRemove={function noRefCheck(value){handleDTplatformChange(value);}}
                                            onSearch={function noRefCheck(){}}
                                            onSelect={function noRefCheck(value){handleDTplatformChange(value);}}
                                            options={[
                                                'Backend',
                                                'Frontend',
                                                'UI/UX',
                                                'Javascript',
                                                'Python',
                                                'React JS',
                                                'Node JS',
                                                'Elixir',
                                                'Flutter',
                                                'Golang',
                                                'RoR',
                                                'Django',
                                                'MERN Stack',
                                                'MEAN Stack'
                                            ]}
                                        />
                                    </div>
                                    <div className='serv-btn-wrap'>
                                        <a href='http' className='form-submit-btn' onClick={handleDTCLick}>احصل على عرض سعر</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="popup confirm-details" style={disPopup ? {display: 'block'}: {display: 'none'}}>
                <div className="popup-header">
                    <div className="popup-title" style={{'font-family':'Alexandria'}}>الخطوة الأخيرة</div>
                    <span className="close-popup" onClick={handleClose}>x</span>
                </div>
                <div className="popup-body">
                    <div className="form-group-section">
                        <input type="text" id="name" className="form-input" placeholder="الأسم"/>
                    </div>
                    <div className="form-group-section">
                        <input type="email" id="email" className="form-input" placeholder="الإيميل"/>
                    </div>
                    <div className="form-group-section">
                        <PhoneInput
                            containerStyle={{'margin-bottom': '15px','direction':'ltr' }}
                            inputClass={'form-input'}
                            value={phone}
                            preferredCountries={['sa','eg','us','ae']}
                            onChange={phone => setPhone(phone)}
                            placeholder={'رقم الجوال'}
                            inputStyle={{'width':'100%', 'height': '46px', 'padding': '.5rem 0px .5rem 48px', 'margin-bottom':'15px !important'}}
                        />
                        {/*<input type="text" id="phone" className="form-input" placeholder="رقم الجوال"/>*/}
                    </div>
                    <div className="form-group-section">
                        <div className="">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6Lcj4JQpAAAAANLbXPvqjdaMQ-47ZqYkCYBofgNo"
                            />
                        </div>
                    </div>
                    <div className="serv-btn-wrap">
                        <a href="#" className="form-submit-btn" onClick={handleSubmission}>ارسل</a>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default ServicespageAr