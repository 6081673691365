import React from 'react'
import Portfoliopage from '../components/Portfoliopage/Portfoliopage'
import PortfoliopageAr from '../components/Portfoliopage/PortfoliopageAr'

const portfolio = () => {
  return (
    <>
      <PortfoliopageAr />
    </>
  )
}

export default portfolio