import React from 'react';
import './ClinicMasonry.css';
import ClinicLogo from '../../assets/portfolio-images/ClinicUnlockLogo1.svg';
import ServicesOrngImg from '../../assets/homepage-images/dedicated-team.svg';
import DurationOrngIcon from '../../assets/portfolio-images/ClockOrng.svg';
import ArabFlag from '../../assets/portfolio-images/ArabFlag.svg';
import PlayStore from '../../assets/portfolio-images/PlayStoreWhite.svg';
import AppleStore from '../../assets/portfolio-images/AppleStoreWhite.svg';
import ClinicAppImg from '../../assets/portfolio-images/clinic-app-img.svg';
import ClinicWebImg from '../../assets/portfolio-images/clinic-web-img.svg';

const ClinicMasonry = () => {
  return (
    <>
        <section className='clinic-section'>
            <div className='clinic-wrap width-90'>
                <h1 className='clinic-heading'>Clinic Unlock</h1>
                <p className='clinic-txt'>Clinic performance software that increases sales and decreases costs for medical centers in the Saudi Kingdom.</p>
                <div className='clinic-card-covers'>
                    <div className='clinic-cardCol'>
                        <div className='cl-sub-cardCol'>
                            <div className='clinic-log-dur-wrap'>
                                <div className='clinic-card-logo'><img src={ClinicLogo} alt='Clinic Unlock' className='clinic-logo-img' /></div>
                                <div className='clinic-card-duration hide-mb'>
                                    <img src={DurationOrngIcon} alt='Duration' className='clinic-duration-icon' />
                                    <h4>Duration</h4>
                                    <p>4 Months</p>
                                </div>
                            </div>
                            <div className='duration-outer-wrap hide-dk'>
                                <div className='clinic-card-duration'>
                                    <img src={DurationOrngIcon} alt='Duration' className='clinic-duration-icon' />
                                    <h4>Duration</h4>
                                    <p>4 Months</p>
                                </div>
                                <div className='clinic-card-country'>
                                    <img src={ArabFlag} alt='Saudi Arabia' className='clinic-flag-icon' />
                                    <h4>Country</h4>
                                    <p>Saudi Arabia</p>
                                </div>
                            </div>
                            <div className='clinic-card-description'>
                                <h3>Clinic Unlock</h3>
                                <p>Our experienced team helped Clinic Unlock create their product in just 4 months, it is the Saudi arabia gateway for optimized clinic performance. This innovative management system empowers clinics and doctors to enhance patient experience and minimize frustrating no-shows.</p>
                            </div>
                        </div>
                        <div className='cl-web-country-wrap hide-mb'>
                            <div className='clinic-card-web-design'>
                                <h4>Website</h4>
                                <p>Expert UI/UX <br />Design.</p>
                                <img src={ClinicWebImg} alt='Clinic Unlock Website' />
                            </div>
                            <div className='clinic-card-country'>
                                <img src={ArabFlag} alt='Saudi Arabia' className='clinic-flag-icon' />
                                <h4>Country</h4>
                                <p>Saudi Arabia</p>
                            </div>
                        </div>
                    </div>
                    <div className='clinic-cardCol hide-mb'>
                        <div className='clinic-card-service'>
                            <h4>Services</h4>
                            <p>Dedicate <br />Team</p>
                            <img src={ServicesOrngImg} alt='Dedicate Team' className='clinic-services-img' />
                        </div>
                        <div className='clinic-card-app'>
                            <h4>Mobile App</h4>
                            <div className='clinic-appstore-wrap'>
                                <a href='https' target='_blank'><img src={PlayStore} alt='Play Store' /></a>
                                <a href='https' target='_blank'><img src={AppleStore} alt='Apple Store' /></a>
                            </div>
                            <div className='clinic-app-card-img-wrap'><img src={ClinicAppImg} alt='Clinic Unlock App' className='' /></div>
                        </div>
                    </div>
                </div>
                <p className='clinic-website-wrap'>Visit Website: <a href='https://www.clinicunlock.com/' target='_blank'>www.clinicunlock.com</a></p>
            </div>
        </section>
    </>
  )
}

export default ClinicMasonry