import React, {useState} from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import HeaderLogo from '../../assets/homepage-images/Nav-logo-ar.svg';
// import DirMenu from '../../assets/homepage-images/direction-logo-blue.svg';
import CustomMenuBtn from './CustomMenuBtn';
import {Routes, useLocation, useSearchParams } from "react-router-dom";

const HeaderAr = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();
    const navigateEnglish = function (e){
        e.preventDefault();
        window.location.href = location.pathname.replace('/ar/','/');
    }
  return (
    <header className='header arabic'>
      <nav className='direction-navbar'>
        <div className='direction-logo-cover'>
          <div className='logo-wraper'>
            <a href='/ar/' className='logo-link'><img src={HeaderLogo} alt='Direction Logo' /></a>
          </div>
        </div>
        <div className={`nav-menu hide-dk`} onClick={() => {setMenuOpen(!menuOpen)}}>
          <CustomMenuBtn menuOpen={menuOpen}/>
          {/* <div className='menu-wrap'><img src={DirMenu} alt='Menu Icon' className='direction-menu' /></div> */}
        </div>
        <div className={`direction-nav-cover ${menuOpen ? 'open' : ''}`}>
          <div className='nav-inner-wrapper'>
            <a href='/ar/' className={'btn '+(location.pathname == "/"?'selected':' ')}>الرئيسية</a>
            <a href='/ar/about' className={'btn '+(location.pathname == "/about"?'selected':' ')}>من نحن</a>
            <a href='/ar/services' className={'btn '+(location.pathname == "/services"?'selected':' ')}>خدماتنا</a>
            <a href='/ar/portfolio' className={'btn '+(location.pathname == "/portfolio"?'selected':' ')}>أعمالنا</a>
            <a href='/ar/case-studies' className={'btn '+(location.pathname == "/case-studies"?'selected':' ')}>دراسات الحالة</a>
          </div>
          <div className='nav-btn-wrap'>
            <a href='#' className='btn language-btn' onClick={navigateEnglish}>English</a>
            <a href='/ar/services' className='btn start-now-nav'>ابدأ الآن</a>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default HeaderAr