import React from 'react';
import './EgentMasonry.css';
import EgentLogo from '../../assets/portfolio-images/EgentLogo.svg';
import ServicesBlueImg from '../../assets/homepage-images/product-solutions.svg';
import DurationBlueIcon from '../../assets/portfolio-images/ClockBlue.svg';
import EgyptFlag from '../../assets/portfolio-images/EgyptFlag.svg';
import EgentWebImg1 from '../../assets/portfolio-images/egent-web-img1.svg';
import EgentWebImg2 from '../../assets/portfolio-images/egent-web-img2.svg';


const EgentMasonry = () => {
  return (
    <>
        <section className='egent-section'>
            <div className='egent-wrap width-90'>
                <h1 className='egent-heading'>Egent</h1>
                <p className='egent-txt'>Superior SEO friendly website for one of the leading medical complex of Ear, nose and throat.</p>
                <div className='egent-card-covers'>
                    <div className='egent-cardCol'>
                        <div className='egent-card-logo'><img src={EgentLogo} alt='Egent' className='egent-logo-img' /></div>
                        <div className='egent-card-web-design webcard1 hide-mb'>
                            <h4>Website</h4>
                            <img src={EgentWebImg1} alt='Egent Website' />
                        </div>
                    </div>
                    <div className='duration-outer-wrap hide-dk'>
                        <div className='egent-card-duration'>
                            <img src={DurationBlueIcon} alt='Duration' className='egent-duration-icon' />
                            <h4>Duration</h4>
                            <p>3 months</p>
                        </div>
                        <div className='egent-card-country'>
                            <h4>Country</h4>
                            <p>Egypt</p>
                            <img src={EgyptFlag} alt='Egypt' className='egent-flag-icon' />
                        </div>
                    </div>
                    <div className='egent-cardCol'>
                        <div className='egent-card-description'>
                            <h3>Egent</h3>
                            <p>We crafted an <strong>SEO-friendly website</strong> that served as a powerful marketing tool, positioning for Egent medical center at the forefront of online visibility, in this project Direction North developed a deep understanding of the medical industry and its challenges; worked on easy navigation, compelling content, localization and mobile optimization.</p>
                        </div>
                        <div className='duration-outer-wrap hide-mb'>
                            <div className='egent-card-web-design webcard2'>
                                <img src={EgentWebImg2} alt='Egent Website' />
                            </div>
                            <div className='egent-card-duration'>
                                <img src={DurationBlueIcon} alt='Duration' className='egent-duration-icon' />
                                <h4>Duration</h4>
                                <p>3 months</p>
                            </div>
                        </div>
                    </div>
                    <div className='egent-cardCol hide-mb'>
                        <div className='egent-card-service'>
                            <h4>Services</h4>
                            <p>Product <br />Development</p>
                            <img src={ServicesBlueImg} alt='Product Development' className='egent-services-img' />
                        </div>
                        <div className='egent-card-country'>
                            <h4>Country</h4>
                            <p>Egypt</p>
                            <img src={EgyptFlag} alt='Egypt' className='egent-flag-icon' />
                        </div>
                    </div>
                </div>
                <p className='egent-website-wrap'>Visit Website: <a href='https://www.egentcenters.com/' target='_blank'>www.egentcenters.com</a></p>
            </div>
        </section>
    </>
  )
}

export default EgentMasonry