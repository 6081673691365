import React from 'react';
import './FlashleadMasonry.css';
import FlashLogo from '../../assets/portfolio-images/FlashLeadlogo.svg';
import ServicesBlueImg from '../../assets/homepage-images/product-solutions.svg';
import DurationBlueIcon from '../../assets/portfolio-images/ClockBlue.svg';
import ArabFlag from '../../assets/portfolio-images/ArabFlag.svg';
import PlayStore from '../../assets/portfolio-images/PlayStoreBlack.svg';
import AppleStore from '../../assets/portfolio-images/AppleStoreBlack.svg';
import FlAppImg from '../../assets/portfolio-images/fl-app-img-ar.svg';
import FlWebImg from '../../assets/portfolio-images/fl-web-img.svg';

const FlashleadMasonryAr = () => {
  return (
    <>
        <section className='flashlead-section'>
            <div className='flashlead-wrap width-90'>
                <h1 className='fl-heading'>فلاش ليد</h1>
                <p className='fl-txt'>منصة متكاملة لإداره المبيعات ودعم العملاء.</p>
                <div className='fl-card-covers'>
                    <div className='fl-cardCol'>
                        <div className='fl-card-logo'><img src={FlashLogo} alt='Flash Lead' className='fl-logo-img' /></div>
                        <div className='fl-card-service hide-mb'>
                            <img src={ServicesBlueImg} alt='Product Development' className='fl-services-img' />
                            <h4>الخدمة</h4>
                            <p>تطوير المنتج</p>
                        </div>
                    </div>
                    <div className='fl-cardCol'>
                        <div className='duration-outer-wrap'>
                            <div className='fl-card-duration'>
                                <img src={DurationBlueIcon} alt='Duration' className='fl-duration-icon' />
                                <h4>مدة المشروع</h4>
                                <p>12 أشهر</p>
                            </div>
                            <div className='fl-card-country'>
                                <img src={ArabFlag} alt='Saudi Arabia' className='fl-flag-icon' />
                                <h4>الدولة</h4>
                                <p>المملكة العربية السعودية</p>
                            </div>
                        </div>
                        <div className='fl-card-description'>
                            <h3>فلاش ليد</h3>
                            <p>عملنا مع فريق فلاش ليد للتفكير في مستقبل المنتج وما يمكن اضافته من إنشاء إمكانيات و مزايا جديدة للمنصة و نجحنا في إنشاء عدة إمكانيات عززت مكانتها في خدمة أكتر من ١٢٠ شركة، تستفيد بالمنصة التي تحسن تجربة العملاء في التواصل و الادارة</p>
                        </div>
                    </div>
                    <div className='fl-cardCol hide-mb'>
                        <div className='fl-card-web-design'>
                            <h4>موقع إلكتروني</h4>
                            <img src={FlWebImg} alt='Flashlead Website' />
                        </div>
                        <div className='fl-card-app'>
                            <h4>تطبيق الموبايل</h4>
                            <div className='fl-appstore-wrap'>
                                <a href='https://play.google.com/store/apps/details?id=com.directionnorth.flashlead' target='_blank'><img src={PlayStore} alt='Play Store' /></a>
                                <a href='https://apps.apple.com/us/app/flashlead/id1468881014' target='_blank'><img src={AppleStore} alt='Apple Store' /></a>
                            </div>
                            <div className='fl-app-card-img-wrap'><img src={FlAppImg} alt='Flashlead App' className='' /></div>
                        </div>
                    </div>
                </div>
                <p className='fl-website-wrap'>Visit Website: <a href='https://www.flashlead.com/' target='_blank'>www.flashlead.com</a></p>
            </div>
        </section>
    </>
  )
}

export default FlashleadMasonryAr