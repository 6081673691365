import './App.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from 'react-router-dom';
import Navbar from './components/Header/Header.jsx';
import NavbarAr from './components/Header/HeaderAr.jsx';
import Footer from './components/Footer/Footer.js';
import FooterAr from './components/Footer/FooterAr.jsx';
import Home from './pages/index.js';
import HomeAr from './pages/index-ar.js';
import About from './pages/about.js';
import AboutAr from './pages/about-ar.js';
import Services from './pages/services.js';
import ServicesAr from './pages/services-ar.js';
import Studies from './pages/studies.js';
import StudiesAr from './pages/studies-ar.js';
import Portfolio from './pages/portfolio.js';
import PortfolioAr from './pages/portfolio-ar.js';
import Thankyou from './pages/thankyou.js';
import ThankyouAr from './pages/thankyou-ar.js';

function App() {
    if(window.location.pathname.includes('/ar/') ) {
        return (

            <Router>
                <NavbarAr/>
                <Routes>
                    <Route path="/" index element={<Home/>}/>
                    <Route path="/ar/" index element={<HomeAr/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/ar/about" element={<AboutAr/>}/>
                    <Route path="/services" element={<Services/>}/>
                    <Route path="/ar/services" element={<ServicesAr/>}/>
                    <Route path="/case-studies" element={<Studies/>}/>
                    <Route path="/ar/case-studies" element={<StudiesAr/>}/>
                    <Route path="/portfolio" element={<Portfolio/>}/>
                    <Route path="/ar/portfolio" element={<PortfolioAr/>}/>
                    <Route path="/thankyou" element={<Thankyou/>}/>
                    <Route path="/ar/thankyou" element={<ThankyouAr/>}/>
                </Routes>
                <FooterAr/>
            </Router>
        );
    }else{
        return (
        <Router>
            <Navbar/>
            <Routes>
                <Route path="/" index element={<Home/>}/>
                <Route path="/ar/" index element={<HomeAr/>}/>
                <Route path="/ar" index element={<HomeAr/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/services" element={<Services/>}/>
                <Route path="/case-studies" element={<Studies/>}/>
                <Route path="/portfolio" element={<Portfolio/>}/>
                <Route path="/thankyou" element={<Thankyou/>}/>
            </Routes>
            <Footer/>
        </Router>
        );
    }
}

export default App;
