import React from 'react';
import HeroIcon from '../../assets/case-studies-images/whatsapp-hero-icon.svg';
import HeroCardIcon1 from '../../assets/case-studies-images/automated-msgs.svg';
import HeroCardIcon2 from '../../assets/case-studies-images/customer-support.svg';
import HeroCardIcon3 from '../../assets/case-studies-images/integration.svg';
import IntegrationImg1 from '../../assets/case-studies-images/reach-customer.svg';
import IntegrationImg2 from '../../assets/case-studies-images/customer-experience.svg';
import IntegrationImg3 from '../../assets/case-studies-images/improve-efficiency.svg';
import IntegrationImg4 from '../../assets/case-studies-images/insight.svg';
import IntegImg1 from '../../assets/case-studies-images/develop-solution.svg';
import IntegImg2 from '../../assets/case-studies-images/connect-system.svg';
import IntegImg3 from '../../assets/case-studies-images/start-messaging.svg';
import DevelopCardIcon1 from '../../assets/case-studies-images/customized-soft1.svg';
import DevelopCardIcon2 from '../../assets/case-studies-images/customized-soft2.svg';
import DevelopCardIcon3 from '../../assets/case-studies-images/customized-soft3.svg';
import DevelopCardIcon4 from '../../assets/case-studies-images/customized-soft4.svg';
import startUpIcon1 from '../../assets/case-studies-images/benefit-startup1.svg';
import startUpIcon2 from '../../assets/case-studies-images/benefit-startup2.svg';
import startUpIcon3 from '../../assets/case-studies-images/benefit-startup3.svg';
import startUpIcon4 from '../../assets/case-studies-images/benefit-startup4.svg';
import teamImg from '../../assets/case-studies-images/tech-team-img.svg';
import teamCardIcon1 from '../../assets/case-studies-images/outsource-icon1.svg';
import teamCardIcon2 from '../../assets/case-studies-images/outsource-icon2.svg';
import teamCardIcon3 from '../../assets/case-studies-images/outsource-icon3.svg';
import teamCardIcon4 from '../../assets/case-studies-images/outsource-icon4.svg';
import teamCardIcon5 from '../../assets/case-studies-images/outsource-icon5.svg';
import teamCardIcon6 from '../../assets/case-studies-images/outsource-icon6.svg';
import teamCardIcon7 from '../../assets/case-studies-images/outsource-icon7.svg';
import teamCardIcon8 from '../../assets/case-studies-images/outsource-icon8.svg';
import featureUpIcon1 from '../../assets/case-studies-images/benefit-feature1.svg';
import featureUpIcon2 from '../../assets/case-studies-images/benefit-feature2.svg';
import featureUpIcon3 from '../../assets/case-studies-images/benefit-feature3.svg';
import featureUpIcon4 from '../../assets/case-studies-images/benefit-feature4.svg';
import featureUpIcon5 from '../../assets/case-studies-images/benefit-feature5.svg';
import {useLocation} from "react-router-dom";

const CaseStudiespageAr = () => {
  const location = useLocation();
  return (
    <div className='caseStudies-page arabic'>
      <section className='tab-section'>
        <div className='case-tab-cover'>
          <div className={'case-tab-link-wrap '+(location.hash == "#WhatsappAPI" || location.hash =='' ?'active':' ')}><a href='#WhatsappAPI'>واتسابAPI للأعمال</a></div>
          <div className={'case-tab-link-wrap '+(location.hash == "#CustomizedDevelopment"?'active':' ')}><a href='#CustomizedDevelopment'>تطوير برامج مخصصة</a></div>
          <div className={'case-tab-link-wrap '+(location.hash == "#TeamTechOutsourcing"?'active':' ')}><a href='#TeamTechOutsourcing'>توفير مصادر خارجية لفريق التطوير</a></div>
        </div>
      </section>
      <section className='case-hero-section' id='WhatsappAPI'>
        <div className='case-hero-wrap1 width-90'>
          <div className='case-hero-txt-wrap'>
            <h1 className='case-hero-heading'>واتساب API للأعمال</h1>
            <p className='case-hero-txt'>لتبسيط الأمر فإنه <span className='case-hero-txt-spn'>يعمل كجسر</span> بين أدوات عملك الحالية وعالم <span className='case-hero-txt-spn'>مراسلة <br className='' />WhatsApp</span>. على عكس التطبيق العادي للواتساب، تتيح هذه البرمجة ما يلي:</p>
          </div>
          <div className='case-hero-img-wrap'><img src={HeroIcon} alt='Whatsapp Direction Icon' /></div>
        </div>
        <div className='case-hero-wrap2 width-90'>
          <p className='case-hero-txt'>على عكس واتساب بيزنس، <span className='case-hero-txt-spn'>يسهل واتساب بيزنس API:</span></p>
          <div className='case-hero-card-wrap'>
            <div className='case-hero-card'>
              <div className='case-inner-wrap'>
                <img src={HeroCardIcon1} alt='Sending automated messages' className='' />
                <p className='case-hero-card-txt'><span> إرسال رسائل وإشعارات تلقائية:</span><br />مثل مشاركة تحديثات الطلب أو تأكيدات الرحلات الجوية وتذكيرات المواعيد والمزيد من التطبيقات التي تعتمد على تحديث أو تذكير العملاء.</p>
              </div>
            </div>
            <div className='case-hero-card'>
              <div className='case-inner-wrap'>
                <img src={HeroCardIcon2} alt='Providing customer support' className='' />
                <p className='case-hero-card-txt'><span>فتح قنوات جديدة لدعم العملاء:</span><br />تقديم المساعدة في وقت سريع من خلال برامج الدردشة الآلية أو الوكلاء المباشرين، مباشرةً من خلال تطبيق WhatsApp.</p>
              </div>
            </div>
            <div className='case-hero-card'>
              <div className='case-inner-wrap'>
                <img src={HeroCardIcon3} alt='Integrating with other systems' className='' />
                <p className='case-hero-card-txt'><span>التكامل مع الأنظمة الأخرى:</span><br /> تبسيط الاتصال عن طريق ربط واجهة برمجة التطبيقات (API) ببرنامج إدارة علاقات العملاء (CRM) أو منصة التجارة الإلكترونية أو أدوات التسويق.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='integration-section'>
        <div className='integration-inner-wrap width-90'>
          <h2 className='integration-heading'>من المميزات الأخرى للتكامل:</h2>
          <div className='integration-col-wrap'>
            <div className='integration-col'>
              <img src={IntegrationImg1} alt='Reach a wider audience' className='inte-col-img' />
              <p className='inte-col-txt'><span>الوصول إلى عدد أكبر</span><br className='' /> من العملاء المتاحة لمستخدمي WhatsApp، والوصول إلى العملاء أينما كانوا بالفعل في البرنامج الأكثر إستخدماً.</p>
            </div>
            <div className='integration-col'>
              <img src={IntegrationImg2} alt='Enhance customer experience' className='inte-col-img' />
              <p className='inte-col-txt'><span>تعزيز تجربة العملاء:</span><br className='' /> تقديم اتصالات مريحة وشخصية من خلال منصة مألوفة.</p>
            </div>
            <div className='integration-col'>
              <img src={IntegrationImg3} alt='Improve efficiency' className='inte-col-img' />
              <p className='inte-col-txt'><span>تحسين الكفاءة:</span><br className='' />  إتمام المهام الروتينية وإدارة المحادثات بفعالية، مما يوفر الوقت والموارد.</p>
            </div>
            <div className='integration-col'>
              <img src={IntegrationImg4} alt='Gain valuable insights' className='inte-col-img' />
              <p className='inte-col-txt'><span>الحصول على رؤية شاملة للعملاء:</span> قم بتحليل بيانات الرسائل لفهم سلوك العملاء وتخطيط التفاعلات المستقبلية</p>
            </div>
          </div>
        </div>
      </section>
      <section className='integ-work-section'>
        <div className='integ-inner-wrap width-90'>
          <div className='integ-heading-wrap'>
            <h3 className='integ-heading'>كيف يعمل<br className='hide-mb' /> التكـــــامل؟</h3>
            <p className='integ-txt'>لن تتفاعل مع تطبيق العادي للواتساب وبدلاً من ذلك تعمل واجهة برمجة التطبيقات (API) خلف الكواليس، في التكامل مع برنامجك الحالي:</p>
          </div>
          <div className='integ-col-wrap'>
            <div className='integ-col'>
              <div className='integ-col-num'>1</div>
              <div className='integ-col-txt-wrap'><p className='integ-col-txt'><span className=''>تطوير حلول رقمية</span> يمكنك إنشاء التكامل الخاص بك لتحقيق هدف أو حل مشكلة معينة لشركتك </p></div>
              <div className='integ-col-img-wrap'><img src={IntegImg1} alt='Develop or choose a solution' className='integ-col-img' /></div>
            </div>
            <div className='integ-col'>
              <div className='integ-col-num'>2</div>
              <div className='integ-col-txt-wrap'><p className='integ-col-txt'><span className=''>قم بتفعيل نظامك</span><br /> قم بدمج واجهة برمجة التطبيقات (API) مع نظام إدارة علاقات العملاء (CRM) أو موقع الويب أو الأدوات الأخرى لإتمام سير العمل</p></div>
              <div className='integ-col-img-wrap'><img src={IntegImg2} alt='Connect your systems' className='integ-col-img' /></div>
            </div>
            <div className='integ-col'>
              <div className='integ-col-num'>3</div>
              <div className='integ-col-txt-wrap'><p className='integ-col-txt'><span className=''> ابدأ بارسال واستقبال الرسائل:</span><br />تفاعل مع عملائك مباشرة عبر منصتك على تطبيق واتساب.</p></div>
              <div className='integ-col-img-wrap'><img src={IntegImg3} alt='Start sending and receiving messages' className='integ-col-img' /></div>
            </div>
          </div>
          <div className='integ-api-wrap'>
            <h3 className='integ-api-heading'>هل تريد إضافة Whatsapp API<br className='' /> إلى برنامجك؟</h3>
            <div className='integ-api-btn-wrap'>
              <a href='/services' className='start-now-btn'>ابدأ الآن</a>
            </div>
          </div>
        </div>
      </section>
      <section className='develop-section' id='CustomizedDevelopment'>
        <div className='develop-col-cover'>
          <div className='develop-col-inner-wrap'>
            <div className='develop-heading-col'>
              <h4 className='develop-heading'>تطوير <span>برامج مخصصة</span></h4>
              <p className='develop-txt'>تطوير البرامج المخصصة يساعدك في <span className='fw-5'>إنشاء برامج مصممة خصيصًا لتلبية الاحتياجات</span> والأهداف الخاصة بشركتك الناشئة. على عكس البرامج الجاهزة، فالبرامج المخصصة مصممه خصيصًا <span className='fw-5'>لتساعد في تسهيل العمل الخاص بك</span> وقاعدة المستخدمين ونموذج الأعمال. وهذا يسمح بما يلي:</p>
            </div>
            <div className='develop-card-col'>
              <div className='develop-card-wrap'>
                <p className='develop-col-txt'> يساعدك هذا في</p>
                <div className='develop-card one'>
                  <img src={DevelopCardIcon1} alt='Enhanced user experience' className='' />
                  <h5 className=''>الميزة التنافسية</h5>
                  <p className=''>تميّز عن الآخرين بحل فريد يعالج المشكلات المحددة التي يواجهها السوق الخاص بك.</p>
                </div>
              </div>
              <div className='develop-card-wrap'>
                <div className='develop-card two'>
                  <img src={DevelopCardIcon2} alt='Scalability' className='' />
                  <h5 className=''>قابلية التوسع</h5>
                  <p className=''>تطوير البرامج التي يمكن أن تنمو مع عملك، والتكيف مع المتطلبات المتغيرة</p>
                </div>
              </div>
              <div className='develop-card-wrap'>
                <div className='develop-card three'>
                  <img src={DevelopCardIcon3} alt='Competitive advantage' className='' />
                  <h5 className=''>تجربة مستخدم محسنة</h5>
                  <p className=''>صمم واجهة سهلة الاستخدام تلبي احتياجات وتوقعات جمهورك المستهدف.</p>
                </div>
              </div>
              <div className='develop-card-wrap'>
                <div className='develop-card four'>
                  <img src={DevelopCardIcon4} alt='Increased efficiency' className='' />
                  <h5 className=''>زيادة الكفاءة</h5>
                  <p className=''>إتمام المهام، وتبسيط العمليات، والقضاء على العمل اليدوي</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='benefit-startup-cover'>
          <h4 className='startup-heading'>المميزات للشركات الناشئة:</h4>
          <div className='startup-inner-wrap'>
            <div className='startup-card'>
              <div className='startup-icon-wrap'><img src={startUpIcon1} alt='Solve specific problems' className='startup-card-icon'/></div>
              <h5 className='startup-card-heading'>حل مشكلات محددة</h5>
              <p className='startup-card-txt'> مواجهة التحديات التي لا تستطيع البرامج العامة التعامل معها، مما يمنحك قدرة تنافسية عليا.</p>
            </div>
            <div className='startup-card'>
              <div className='startup-icon-wrap'><img src={startUpIcon2} alt='Validate your idea' className='startup-card-icon'/></div>
              <h5 className='startup-card-heading'>التحقق من صحة فكرتك</h5>
              <p className='startup-card-txt'>قم بتطوير منتج أولي اقابل للتطوير لاختبار مفهومك وجمع تعليقات المستخدمين قبل الاستثمار بكثافة</p>
            </div>
            <div className='startup-card'>
              <div className='startup-icon-wrap'><img src={startUpIcon3} alt='Attract investors' className='startup-card-icon'/></div>
              <h5 className='startup-card-heading'>جذب المستثمرين</h5>
              <p className='startup-card-txt'>أظهر التزامك بالابتكار والرؤية طويلة المدى من خلال حل برمجي مخصص</p>
            </div>
            <div className='startup-card'>
              <div className='startup-icon-wrap'><img src={startUpIcon4} alt='Build brand loyalty' className='startup-card-icon'/></div>
              <h5 className='startup-card-heading'>بناء الولاء للعلامة التجارية</h5>
              <p className='startup-card-txt'>تقديم تجربة مستخدم استثنائية تميزك عن المنافسين</p>
            </div>
          </div>
        </div>
        <div className='go-cover width-90'>
          <div className='go-wrap'>
            <h3 className='go-heading'>على استعداد لتجربة<br className='' /> عمل منتج مخصص؟</h3>
            <p className='go-txt'>إذا كنت تتطلع إلى إنشاء حل رقمي يعكس رؤية شركتك الناشئة ويدفعك<br className='' /> إلى الأمام، فإن التطوير المخصص يستحق الاستكشاف.</p>
            <div className='go-btn-wrap'>
              <a href='/services' className='start-now-btn'>ابدأ الآن</a>
            </div>
          </div>
        </div>
      </section>
      <section className='team-section' id='TeamTechOutsourcing'>
        <div className='team-inner-wrap width-90'>
          <div className='team-cover'>
            <div className='team-txt-wrap'>
              <h4 className='team-heading'>توفير مصادر خارجية لفريق التطوير</h4>
              <p className='team-txt1'><span className='fw-4'>تسهيل تطوير منتجك:</span> أطلق العنان لرؤيتك من خلال مواردنا المخصصة; يتطلب تطوير منتج متطور الخبرة والكفاءة.</p>
              <p className='team-txt2'>يزودك خدماتنا الشاملة لتطوير المنتجات الرقمية والأدوات والموارد الأساسية لتحويل رؤيتك إلى واقع:</p>
            </div>
            <div className='team-img-wrap'><img src={teamImg} alt='Tech Team Outsourcing' className='team-img' /></div>
          </div>
          <div className='team-card-cover'>
            <div className='team-card-wrap'>
              <div className='team-card rct'>
                <div className='team-card-img-wrap'><img src={teamCardIcon1} alt='Free Cost Estimation' className=''/></div>
                <div className='team-card-txt-wrap'>
                  <h6>تقدير مجاني للتكلفة</h6>
                  <p>ابدأ بثقة مع فهم شفاف لتكاليف المشروع والجداول الزمنية.</p>
                </div>
              </div>
              <div className='team-card rct'>
                <div className='team-card-img-wrap'><img src={teamCardIcon2} alt='Prototype and Wireframing' className=''/></div>
                <div className='team-card-txt-wrap'>
                  <h6>النموذج الأولي والتصميم</h6>
                  <p>قم بتجربة المنتج الخاص بك وقم بتحسينه باستخدام تصميمات أولية لرحلة العميل</p>
                </div>
              </div>
              <div className='team-card rct'>
                <div className='team-card-img-wrap'><img src={teamCardIcon3} alt='Timely Delivery' className=''/></div>
                <div className='team-card-txt-wrap'>
                  <h6>التسليم في الوقت المحدد</h6>
                  <p>يمكنك الوفاء بالمواعيد النهائية للإطلاق من خلال ممارسات التطوير الذكية والتواصل الشفاف.</p>
                </div>
              </div>
            </div>
            <div className='team-card-wrap'>
              <div className='team-card sqr'>
                <div className='team-card-img-wrap'><img src={teamCardIcon4} alt='Expert UI/UX Design' className='' /></div>
                <div className='team-card-txt-wrap'>
                  <h6>تصميم احترافي واجهة/تجربة المستخدم</h6>
                  <p>اجذب جمهورك بواجهات بديهية ومذهلة بصريًا مصممة خصيصًا لتلبية احتياجات المستخدم.</p>
                </div>
              </div>
              <div className='team-card sqr'>
                <div className='team-card-img-wrap'><img src={teamCardIcon5} alt='Free Dedicated Quality Assurance' className='' /></div>
                <div className='team-card-txt-wrap'>
                  <h6>مسؤول ضمان الجودة</h6>
                  <p>ضمان وظائف لا تشوبها شائبة وتجربة مستخدم مجربة من خلال اختبارات شاملة.</p>
                </div>
              </div>
            </div>
            <div className='team-card-wrap'>
              <div className='team-card rct'>
                <div className='team-card-img-wrap'><img src={teamCardIcon6} alt='Premium Code Quality' className=''/></div>
                <div className='team-card-txt-wrap'>
                  <h6>جودة تعليمات برمجية متميزة</h6>
                  <p>استمتع براحة البال من التحقق من استخدمنا  خطوات برمجية قوية وآمنة وموثقة جيدًا.</p>
                </div>
              </div>
              <div className='team-card rct'>
                <div className='team-card-img-wrap'><img src={teamCardIcon7} alt='Free Dedicated Project Management' className=''/></div>
                <div className='team-card-txt-wrap'>
                  <h6>مسؤول إدارة للمشروع</h6>
                  <p>ابق على اطلاع مع مدير مشروع مخصص يشرف على كل خطوات التنفيذ.</p>
                </div>
              </div>
              <div className='team-card rct'>
                <div className='team-card-img-wrap'><img src={teamCardIcon8} alt='Weekly Sprints' className=''/></div>
                <div className='team-card-txt-wrap'>
                  <h6>الخطة الأسبوعية SPRINT</h6>
                  <p>تتبع التقدم في الوقت الفعلي من خلال معالم واضحة وتواصل منتظم من خلال الخطة الأسبوعية</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='feature-section'>
        <div className='feature-inner-wrap'>
          <div className='benefit-feature-cover'>
            <h4 className='feature-heading'>فوائد تتخطى المتوقع:</h4>
            <div className='feature-inner-wrap'>
              <div className='feature-card'>
                <div className='feature-icon-wrap'><img src={featureUpIcon1} alt='Reduced Risks' className='feature-card-icon'/></div>
                <h5 className='feature-card-heading'>تقليل مخاطر المشروع</h5>
                <p className='feature-card-txt'>التخفيف من تعقيدات التطوير و التأخيرات المحتملة من خلال العمليات التي أثبتت جدواها والمهنيين ذوي الخبرة.</p>
              </div>
              <div className='feature-card'>
                <div className='feature-icon-wrap'><img src={featureUpIcon2} alt='Increased Efficiency' className='feature-card-icon'/></div>
                <h5 className='feature-card-heading'>زيادة الكفاءة</h5>
                <p className='feature-card-txt'>ركز على نقاط القوة الأساسية في عملك بينما يتعامل فريقنا مع التعقيدات التقنية.</p>
              </div>
              <div className='feature-card'>
                <div className='feature-icon-wrap'><img src={featureUpIcon3} alt='Enhanced Collaboration' className='feature-card-icon'/></div>
                <h5 className='feature-card-heading'>التعاون المشترك</h5>
                <p className='feature-card-txt'>استمتع بالتواصل المفتوح والشراكة الشفافة طوال رحلة التطوير. </p>
              </div>
              <div className='feature-card'>
                <div className='feature-icon-wrap'><img src={featureUpIcon4} alt='Cost-Effectiveness' className='feature-card-icon'/></div>
                <h5 className='feature-card-heading'>فاعلية التكلفة</h5>
                <p className='feature-card-txt'>يمكنك الوصول إلى الموارد والخبرات المتميزة دون تحمل تكاليف بناء فريق داخلي دائم</p>
              </div>
              <div className='feature-card'>
                <div className='feature-icon-wrap'><img src={featureUpIcon5} alt='Faster Time to Market' className='feature-card-icon'/></div>
                <h5 className='feature-card-heading'>وقت أسرع لإطلاق المنتج</h5>
                <p className='feature-card-txt'>قم بتسريع إطلاق منتجك من خلال دورات تطوير فعالة وتسليمات واضحة. </p>
              </div>
            </div>
          </div>
        </div>
        <div className='potential-cover width-90'>
          <div className='potential-wrap'>
            <h3 className='potential-heading'>أطلق العنان لإمكانات<br className='hide-mb' /> الكاملة لافكار التطوير</h3>
            <p className='potential-txt'>اتصل بنا اليوم للحصول على استشارة مجانية وتجربة<br className='hide-mb' /> الفرق الذي يمكن أن تحدثه الموارد المخصصة.</p>
            <div className='potential-btn-wrap'>
              <a href='/services' className='start-now-btn'>ابدأ الآن</a>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default CaseStudiespageAr