import React from 'react';
import './APIMasonry.css';
import APILogo from '../../assets/portfolio-images/APILogo.svg';
import ServicesOrngImg from '../../assets/homepage-images/dedicated-team.svg';
import DurationBlueIcon from '../../assets/portfolio-images/ClockBlue.svg';
import UAEFlag from '../../assets/portfolio-images/UAEFlag.svg';

const APIMasonryAr = () => {
  return (
    <>
        <section className='api-section'>
            <div className='api-wrap width-90'>
                <h1 className='api-heading'>تكامل Whatsapp API (عميل سري)</h1>
                <p className='api-txt'>موقع ويب متميز مألوف لمحركات البحث (SEO) لأحد المجمعات الطبية الرائدة في طب الأذن والأنف والحنجرة.</p>
                <div className='api-card-covers'>
                    <div className='api-cardCol'>
                        <div className='api-card-logo'><img src={APILogo} alt='Whatsapp API' className='api-logo-img' /></div>
                        <div className='api-card-duration hide-mb'>
                            <img src={DurationBlueIcon} alt='Duration' className='api-duration-icon' />
                            <h4>المدة</h4>
                            <p>2 أشهر</p>
                        </div>
                    </div>
                    <div className='api-cardCol'>
                        <div className='api-card-description'>
                            <h3>دمج تطبيق واتساب عبر<br /> تقنية API</h3>
                            <p>من خلال الاستفادة من شراكتنا القوية و خبرتنا العميقة مع Meta، أطلقنا العنان للمراسلة السلسة لشركة ناشئة رائدة في مجال التكنولوجيا. وفي هذا المشروع، حققنا المطلوب من  توفير قناة  للدعم السريع للعملاء. بالإضافة إلى إعادة الاستهداف من خلال إعلانات العروض الجديدة التي تصل إلى العملاء مباشرةً على منصه الوتساب   ونجحنا في تحقيق  الامتثال الكامل لمتطلبات خصوصية الشركة الناشئة، وتعزيز ولاء العملاء.</p>
                        </div>
                    </div>
                    <div className='duration-outer-wrap hide-dk'>
                        <div className='api-card-duration'>
                            <img src={DurationBlueIcon} alt='Duration' className='api-duration-icon' />
                            <h4>المدة</h4>
                            <p>2 أشهر</p>
                        </div>
                        <div className='api-card-country'>
                            <img src={UAEFlag} alt='UAE' className='api-flag-icon' />
                            <h4>الدولة</h4>
                            <p>الإمارات العربية المتحدة.</p>
                        </div>
                    </div>
                    <div className='api-cardCol hide-mb'>
                        <div className='api-card-service'>
                            <h4>الخدمة</h4>
                            <p> توفير خدمات الفريق</p>
                            <img src={ServicesOrngImg} alt='Dedicate Team' className='api-services-img' />
                        </div>
                        <div className='api-card-country'>
                            <img src={UAEFlag} alt='UAE' className='api-flag-icon' />
                            <h4>الدولة</h4>
                            <p>الإمارات العربية المتحدة.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default APIMasonryAr